.userBookings {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  width: 100%;
  margin-top: 25px;

  > section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    padding: 25px;
    max-width: 1360px;
    margin: 0 auto;
    border-radius: 20px;
  }

  div {
    width: 100%;
  }

  img {
    width: 240px;
    height: auto;
    max-height: 160px;
    border-radius: 5px;
  }

  article {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    position: relative;
    background-color: #fff;
    border-radius: 20px;
    border-top: 1px solid #c5c5c573;
    border-right: 1px solid #c5c5c573;
    border-left: 1px solid #c5c5c573;
    box-shadow: rgba(191, 191, 191, 0.5) 0px 5px 10px;
    cursor: pointer;
    width: 45%;
    min-width: 600px;
    margin-bottom: 20px;
    overflow: hidden;
    transition: box-shadow 0.5s ease-in-out;
  }

  article:hover,
  article:active {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
  }

  article aside {
    display: flex;
    align-items: stretch;
    width: 100%;
    gap: 20px;
    padding: 15px 25px;
  }

  article aside:nth-child(2) {
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease-in;
    width: 100%;
    padding: 0;
  }

  article aside div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  span em {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  span em:first-child::before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27%3E%3Cpath fill=%27%23000%27 d=%27M12 4a4 4 0 0 1 4 4 4 4 0 0 1-4 4 4 4 0 0 1-4-4 4 4 0 0 1 4-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4%27/%3E%3C/svg%3E");
    display: inline-block;
    width: 1.25em;
    height: 1.25em;
    margin-right: 5px;
  }

  h4 {
    font-size: 1.75em;
  }

  p {
    font-size: 1.1em;
    line-height: 1.3em;
    text-align: right;
  }

  p strong {
    color: #017143;
    font-family: Poppins, sans-serif;
    font-size: 1.4em;
    font-weight: 600;
  }

  button {
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #404040;
    color: #fff;
    letter-spacing: 0.05em;
    cursor: pointer;
  }

  strong {
    text-transform: uppercase;
  }
}

.containerBooking {
  > div {
    margin-top: 20px;
  }

  div p {
    text-align: start;
    font-size: 1.1em;
  }

  div p br {
    display: none;
  }

  div p:last-child {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    background: linear-gradient(155deg, #017143, #0da72c 70%, #209323);
    padding: 10px;
  }

  div p:last-child span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    gap: 9px;
  }

  div p:last-child span:last-child,
  div p:last-child span:first-child {
    flex: 0.5;
  }

  div p:last-child span a,
  div p:last-child span b {
    text-align: center;
    font-weight: 400;
    font-size: 0.95em;
    color: #fff;
    text-decoration: none;
  }

  div p:last-child span b br {
    display: block;
  }

  div p:last-child span:last-child b:hover,
  div p:last-child span:first-child a:hover {
    text-decoration: underline;
  }
}

.statusBooking {
  color: #fff;
  border: none;
  border-radius: 20px;
  border-top: 1px solid hsla(0, 0%, 77%, 0.451);
  box-shadow: 0 5px 10px hsla(0, 0%, 75%, 0.5);
  cursor: pointer;
  font-size: 0.9em !important;
  letter-spacing: 0.05em;
  padding: 5.5px 12.5px !important;
  transition: box-shadow 0.5s ease-in-out;
  width: fit-content;
  margin: 10px 0 10px auto;
}

.inProgress {
  background-color: #c17500;
}

.validate {
  background: linear-gradient(-180deg, #00a314, #0da72c 30%, #2fab18);
}

.payed {
  background: #0d3ea7;
}

.canceled {
  background: linear-gradient(155deg, #e30000, #d40000 70%, #d60202);
}

@media (max-width: 1500px) {
  .userBookings {
    > section {
      justify-content: flex-start;
      gap: 5px;
      padding: 0;
    }
  }
}

@media (max-width: 1280px) {
  .userBookings {
    article {
      margin: auto auto 20px;
    }
  }
}

@media (max-width: 768px) {
  .userBookings {
    article {
      width: 100%;
      min-width: 320px;
    }

    span em {
      font-size: 0.9em;
    }

    div > span {
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;
    }

    div p:last-child span b,
    div p:last-child span a {
      font-size: 0.85em;
    }
  }
}

@media (max-width: 620px) {
  .userBookings {
    article aside {
      padding: 15px;
    }

    article p {
      padding-left: 0;
      font-weight: 400;
    }

    article p:first-child {
      padding-bottom: 20px;
      border-bottom: 1px solid hsl(0deg 0% 60% / 19%);
    }

    article p:nth-child(2) {
      text-align: right;
      padding-right: 0;
      margin-bottom: 10px;
    }

    article p b {
      margin-left: 2px;
    }
  }
}

@media (max-width: 515px) {
  .userBookings {
    article {
      min-width: 285px;
    }

    article aside {
      flex-direction: column;
      align-items: center;
    }

    div p:last-child {
      flex-direction: column;
    }

    div p:last-child span:nth-child(2) {
      padding-left: 5px;
    }

    div p:last-child span:last-child {
      padding-left: 7.5px;
    }

    article aside:nth-child(2) {
      justify-content: flex-end;
    }
  }
}
