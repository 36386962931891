.calendar {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 70px;
  width: 100%;
  max-height: 600px;
  overflow-y: auto;
  padding-bottom: 10px;
}
.calendar h5 {
  text-transform: capitalize;
  font-size: 1.05em;
  text-align: center;
}
.calendar article {
  width: 260px;
  height: 235px;
}

.rowCalendar {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.rowCalendar .days,
.rowCalendar .dates {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.rowCalendar .days {
  border-bottom: 1px solid #999;
  margin-bottom: 5px;
}
.rowCalendar .dates {
  justify-content: flex-start;
}
.rowCalendar .dates:nth-child(2) {
  justify-content: flex-end;
}
.rowCalendar .days span,
.rowCalendar .dates span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  text-align: start;
}
.rowCalendar .days span {
  font-size: 0.875em;
}
.rowCalendar .dates span {
  font-size: 0.9em;
  color: #b3b3b3;
  user-select: none;
  background-color: transparent;
  border-radius: 5px;
  transition: background-color 0.15s ease-in-out, border-radius 0.25s ease-in-out;
  margin: 1px;
}
.rowCalendar .dates span:not(.available) {
  text-decoration: line-through;
}
.rowCalendar .dates span.available {
  cursor: pointer;
  color: #0da72c;
  user-select: initial;
  font-size: 0.95em;
  background-color: rgba(54, 210, 54, 0.1411764706);
}
.rowCalendar .dates span.available b {
  font-weight: 600;
}
.rowCalendar .dates span.available.dateActive,
.rowCalendar .dates span.available:hover {
  background-color: rgba(11, 211, 51, 0.8352941176);
  border-radius: 10px;
}
.rowCalendar .dates span.available.dateActive b,
.rowCalendar .dates span.available:hover b {
  text-shadow: 0 0 20px rgb(0, 0, 0);
  color: #fff;
}

.calendar::-webkit-scrollbar {
  display: block;
  width: 5px;
}

.calendar::-webkit-scrollbar-thumb {
  background-color: #8e8e8e;
}

.calendar[id=bookEnd] {
  margin-bottom: 40px;
}
.calendar[id=bookEnd] .dates span.available {
  user-select: initial;
  cursor: pointer;
  color: #0d3ea7;
  background-color: rgba(23, 88, 147, 0.2);
}
.calendar[id=bookEnd] .dates span.available.dateActive,
.calendar[id=bookEnd] .dates span.available:hover {
  background-color: rgba(11, 58, 211, 0.8352941176);
  border-radius: 10px;
}
.calendar[id=bookEnd] .dates span.available.dateActive b,
.calendar[id=bookEnd] .dates span.available:hover b {
  text-shadow: 0 0 20px rgb(0, 0, 0);
  color: #fff;
}

.noCalendar {
  margin-top: 50px;
  font-weight: 600;
}

.noCalendar::before {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: -0.125em;
  margin-right: 10px;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 11.5v5m0-8.99l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10'/%3E%3C/svg%3E");
}

@media (max-width: 1000px) {
  .rowCalendar .dates span:not(.available) {
    font-weight: 500;
  }
}
@media (max-width: 768px) {
  .calendar {
    justify-content: space-around;
    gap: 0;
  }
  .calendar article {
    height: 300px;
    width: 250px;
  }
}
@media (max-width: 620px) {
  .calendar {
    align-items: center;
    flex-wrap: nowrap;
    gap: 45px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 90vw;
    overflow-x: scroll;
  }
  .calendar h5 {
    font-size: 0.9em;
  }
  .rowCalendar .dates span.available b {
    font-size: 0.875em;
  }
  .rowCalendar .days span,
  .rowCalendar .dates span {
    width: 32.5px;
    height: 32.5px;
  }
  .rowCalendar .days span {
    font-size: 0.8em;
  }
}