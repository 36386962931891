@keyframes appear {
  0% {
    opacity: 0.5;
    transform: translateY(90vh);
  }
  100% {
    opacity: 1;
    transform: translateY(1px);
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
    transform: translateY(1px);
  }
  100% {
    opacity: 0.5;
    transform: translateY(200vh);
  }
}

.summary {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 50px;
  padding: 60px 15vw 100px;
  min-height: 500px;
  width: 100%;
  background-color: #f1f1f1f1;

  img {
    border-radius: 10px;
    box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.14);
    height: 380px;
    margin: 0 5px;
    width: 550px;
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1360px;
    width: 100%;
    transform: translateY(90vh);
    opacity: 0.5;
    animation: appear 2s linear forwards;
  }

  section > p {
    font-size: 1.1em;
    margin-bottom: 20px;
  }

  section > p b {
    font-size: 1.15em;
    font-family: Poppins, sans-serif;
    color: #017143;
  }

  h3 {
    font-size: 1.5em;
    font-weight: 600;
    margin: 5px 0 15px 0;
    color: #2f2f2f;
  }

  h2 {
    color: #333;
    font-family: Poppins, sans-serif;
    font-size: 2em;
    font-weight: 600;
    line-height: 1.15em;
    margin-bottom: 20px;
    padding-left: 20px;
  }

  article {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    background-color: #fff;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgb(0 0 0 / 5%);
    margin-bottom: 20px;
    width: 100%;
  }

  article div {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-left: 1px solid #999;
    padding-left: 30px;
  }

  article:first-child div {
    width: 50%;
  }

  article div:first-child {
    padding-left: 0;
    border-left: none;
  }

  article div:last-child {
    min-width: 320px;
  }

  article div:not(:first-child) p {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 6px;
    padding: 15px 0;
    border-bottom: 1px solid #99999975;
    line-height: 32px;
    letter-spacing: 0.5px;
  }

  article div:nth-child(2) p:nth-child(3) {
    padding-bottom: 10px;
  }

  article div:not(:first-child) p b {
    display: block;
    width: 100%;
    font-size: 1.15em;
    letter-spacing: normal;
    color: #444;
  }

  article div:not(:first-child) p span {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    margin: 7.5px;
  }

  article div p span b {
    font-size: 1em;
  }

  article div p:last-child {
    border-bottom: none;
    font-size: 1em;
    padding-top: 50px;
  }

  article div p:last-child b {
    width: auto;
    font-size: 2em;
    font-weight: 600;
    letter-spacing: 1px;
    color: #017143;
  }

  article:last-child {
    flex-wrap: wrap;
  }

  article:last-child p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1em;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid hsla(0, 0%, 60%, 0.459);
  }

  article:last-child p:nth-child(2) {
    padding-bottom: 20px;
    margin-bottom: 0;
    border-bottom: none;
  }

  article:last-child div {
    padding-left: 0;
    flex-direction: row;
    border-left: none;
  }

  article:last-child aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 50%;
    text-align: center;
  }

  article:last-child a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    background: #017143;
    background: linear-gradient(-180deg, #00a314, #0da72c 30%, #2fab18);
    border: none;
    border-radius: 40px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    color: #fff;
    cursor: pointer;
    font-size: 1em;
    font-weight: 500;
    height: auto;
    letter-spacing: 1px;
    max-width: fit-content;
    padding: 12px 17.5px;
    text-decoration: none;
    transition: background-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  }

  article:last-child a[href="#/summary"] {
    background: #0d3ea7;
  }

  article:last-child a[href="#/login?param=register"]::after {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    vertical-align: -0.125em;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M15 14c-2.67 0-8 1.33-8 4v2h16v-2c0-2.67-5.33-4-8-4m-9-4V7H4v3H1v2h3v3h2v-3h3v-2m6 2a4 4 0 0 0 4-4a4 4 0 0 0-4-4a4 4 0 0 0-4 4a4 4 0 0 0 4 4'/%3E%3C/svg%3E");
  }

  article:last-child a[href="#/login"]::after {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    vertical-align: -0.125em;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M12 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4'/%3E%3C/svg%3E");
  }

  article:last-child a[href="#/?param=modify"]::after {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    vertical-align: -0.125em;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='white' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath d='M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352z'/%3E%3Cpath d='M19.846 4.318a2.148 2.148 0 0 0-.437-.692a2.014 2.014 0 0 0-.654-.463a1.92 1.92 0 0 0-1.544 0a2.014 2.014 0 0 0-.654.463l-.546.578l2.852 3.02l.546-.579a2.14 2.14 0 0 0 .437-.692a2.244 2.244 0 0 0 0-1.635M17.45 8.721L14.597 5.7L9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z'/%3E%3C/g%3E%3C/svg%3E");
  }

  article:last-child a:hover {
    background-color: #0da72c;
  }

  article:last-child a:active {
    box-shadow: inset rgba(0, 0, 0, 0.2) 0px 2px 10px;
  }

  article:last-child aside:first-child {
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid hsla(0, 0%, 60%, 0.459);
  }
}

@media (max-width: 1800px) {
  .summary {
    padding: 60px 20px 100px;
  }
}

@media (max-width: 1280px) {
  .summary {
    img {
      margin: auto 5px;
      max-width: 100%;
      height: auto;
    }

    article div {
      justify-content: space-between;
    }

    article div:first-child {
      padding-left: 0;
      padding-right: 20px;
    }
  }
}

@media (max-width: 1000px) {
  .summary {
    section > h2 {
      font-size: 2em;
      padding-left: 0;
    }

    section > p {
      padding-left: 10px;
    }

    article {
      flex-direction: column;
      justify-content: space-between;
    }

    article div {
      border-left: none;
      padding-left: 0;
    }

    article div:last-child {
      min-width: 0;
    }

    article div:first-child {
      align-items: center;
      padding-right: 0;
    }
  }
}

@media (max-width: 768px) {
  .summary {
    background-color: #fff;

    section {
      align-items: center;
    }

    h2 {
      text-align: center;
    }

    article {
      box-shadow: none;
      padding: 40px 20px;
    }

    article:last-child > p {
      flex-direction: column;
      gap: 20px;
    }
  }
}

@media (max-width: 620px) {
  .summary {
    section > h2 {
      font-size: 1.75em;
      margin-bottom: 10px;
    }

    article div:not(:first-child) p b {
      font-size: 1.1em;
    }

    article div:nth-child(2) p:nth-child(3) {
      padding-left: 0;
      padding-right: 0;
    }

    article div:not(:first-child) p span svg {
      gap: 5px;
    }

    article div:not(:first-child) p span svg {
      width: 32px !important;
      height: 32px !important;
    }

    article:last-child a {
      font-size: 0.9em;
    }

    article:last-child > div {
      flex-direction: column;
      gap: 30px;
    }

    article:last-child > div aside {
      width: 100%;
    }

    article:last-child aside:first-child {
      border-right: none;
      margin-right: 0;
      padding-right: 0;
    }

    article:last-child > p,
    article p span {
      text-align: center;
    }

    article:last-child a[href="#/summary"] {
      margin: 0 10px;
      font-size: 0.9em;
    }

    article:last-child aside,
    article p {
      font-weight: 400;
      padding: 0 10px 20px;
    }
  }
}

@media (max-width: 515px) {
  .summary {
    padding: 30px 10px 100px;

    article {
      padding: 20px 0;
    }

    article div p {
      justify-content: flex-start;
    }

    article:last-child {
      gap: 30px;
      align-items: center;
    }

    article div p:last-child b {
      font-size: 2em;
    }
  }
}

@media (max-width: 515px) {
  .summary {
    h2 {
      font-size: 1.5em;
    }

    h3 {
      font-size: 1.25em;
    }

    article div:not(:first-child) p {
      padding: 15px;
    }
  }
}
