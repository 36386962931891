.formBook {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 54vw;
  height: 100%;
  max-width: 1140px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px;
  backdrop-filter: blur(20px) brightness(0.75);
  transition: 0.5s ease;
  padding: 0 10px;
}
.formBook aside {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.9vw;
  padding: 13px 0;
  height: auto;
  transition: transform 0.75s ease-in-out, height 0.5s ease-in;
}
.formBook div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}
.formBook aside:first-child label {
  display: flex;
  align-items: center;
  color: #2d2d2d;
  font-size: 0.75em;
  letter-spacing: 0.07em;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  cursor: pointer;
  pointer-events: none;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in;
}
.formBook aside:first-child div {
  border-radius: 10px;
  overflow: hidden;
}
.formBook aside:nth-child(2) label {
  font-size: 0.9em;
  text-shadow: 0 0px 10px rgba(0, 0, 0, 0.42);
  color: whitesmoke;
  letter-spacing: 0.07em;
  cursor: pointer;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in;
}
.formBook aside:first-child div,
.formBook aside:nth-child(2) div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  position: relative;
}
.formBook aside:nth-child(2) div {
  cursor: pointer;
}
.formBook aside:nth-child(2) {
  padding: 10px 0 20px;
}
.formBook select,
.formBook input:not(input[type=checkbox]) {
  display: block;
  width: 100%;
  min-height: 35.5px;
  min-width: 190px;
  padding: 0.3rem 0.75rem;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.5;
  font-family: Lato, sans-serif;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #fff;
  outline: none;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  transition: border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out, border-radius 0.5s ease-in-out;
}
.formBook aside:first-child select:focus + label,
.formBook aside:first-child input:focus + label {
  color: transparent;
  background-color: transparent;
}
.formBook input[type=checkbox] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  background-color: hsla(0, 0%, 96%, 0.86);
  border-radius: 5px;
  width: 17.5px;
  height: 17.5px;
  transition: background-color 0.25s ease-in-out;
}
.formBook input[type=checkbox]:checked {
  z-index: 1;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23666' d='M12 7a5 5 0 1 1-4.995 5.217L7 12l.005-.217A5 5 0 0 1 12 7'/%3E%3C/svg%3E");
  width: 17.5px;
  height: 17.5px;
  background-color: #fff;
}
.formBook button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4.5px;
  padding: 0.375rem 1em;
  font-size: 0.9em;
  font-weight: 400;
  letter-spacing: 0.08em;
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: #464646;
  min-width: 126px;
  min-height: 44px;
  cursor: pointer;
  transition: background-color 0.25s ease-in;
}
.formBook button:disabled {
  background-color: rgba(33, 37, 41, 0.5882352941);
  color: #f9f9f9;
  cursor: default;
}
.formBook button:disabled:hover {
  filter: brightness(1);
}
.formBook > ul {
  justify-content: center !important;
}
.formBook ul div {
  border-left: none;
}
.formBook ul.error_messages {
  height: 0;
  margin-bottom: 0;
  transition: height 0.75s ease-in-out, margin-bottom 0.75s ease-in-out;
}
.formBook ul.error_messages.appear {
  height: 25px;
  margin-bottom: 20px;
  transition: height 0.5s ease-in, margin-bottom 0.5s ease-in-out;
}
.formBook span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #c17500;
  border-radius: 20px;
  padding: 7.5px 12.5px;
  color: #fff;
  font-weight: 500;
  font-size: 0.9em;
  letter-spacing: 1px;
}

@media (max-width: 1800px) {
  .formBook {
    width: 100%;
  }
}
@media (max-width: 780px) {
  .formBook aside:nth-child(2) {
    justify-content: space-evenly;
  }
  .formBook aside:nth-child(2) div label {
    font-size: 0.8em;
  }
  .formBook aside:first-child label {
    font-size: 0.75em;
    color: rgba(102, 102, 102, 0.9098039216);
  }
}
@media (min-width: 621px) {
  .formBook menu {
    display: none;
  }
  .formBook aside:nth-child(2) {
    height: auto;
    transform: translateY(1px);
  }
}
@media (max-width: 620px) {
  .formBook {
    box-shadow: none;
    backdrop-filter: none;
  }
  .formBook aside:first-child {
    padding-bottom: 0;
    justify-content: space-around;
  }
  .formBook aside:first-child label {
    padding-left: 5px;
    color: rgba(102, 102, 102, 0.9098039216);
    font-weight: 500;
  }
  .formBook aside:first-child div {
    width: 47%;
    overflow: hidden;
    margin-bottom: 2.5px;
  }
  .formBook aside:first-child div:first-child {
    order: 1;
  }
  .formBook aside:first-child div:nth-child(2) {
    order: 3;
  }
  .formBook aside:first-child div:nth-child(3) {
    order: 4;
  }
  .formBook aside:first-child div:nth-child(4) {
    order: 2;
  }
  .formBook aside:nth-child(2) {
    height: 0;
    padding: 0;
    overflow: hidden;
    transform: translateY(200px);
  }
  .formBook aside:nth-child(2).active {
    height: 70px;
    padding: 0 13px;
    transform: translateY(1px);
  }
  .formBook menu {
    height: 30px;
    width: 30%;
    color: #fff;
    list-style: none;
    margin-top: -25px;
    z-index: 2;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M12 14.702L6.692 9.394l.708-.707l4.6 4.6l4.6-4.6l.708.707z'/%3E%3C/svg%3E");
  }
  .formBook aside:nth-child(2).active + menu {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='m7.4 14.702l-.708-.708L12 8.687l5.308 5.307l-.708.708l-4.6-4.6z'/%3E%3C/svg%3E");
    margin-top: 5px;
  }
  .formBook button,
  .formBook button:disabled {
    order: 5;
    width: 15%;
    max-width: none;
    min-width: 65px;
    margin-left: auto;
    font-size: 0;
    color: transparent;
    background-color: transparent;
    padding-top: 0;
  }
  .formBook select,
  .formBook input:not(input[type=checkbox]) {
    min-height: 30px;
    font-size: 0.75em;
  }
  .formBook ul.error_messages.appear {
    margin-bottom: 45px;
  }
}
@media (max-width: 515px) {
  .formBook select,
  .formBook input:not(input[type=checkbox]) {
    min-width: 0;
  }
  .formBook aside:nth-child(2) div {
    flex-grow: 1;
  }
  .formBook aside:nth-child(2) div label {
    font-weight: 400;
  }
  .formBook aside:nth-child(2) {
    gap: 7.5px;
  }
  .formBook aside:nth-child(2) div {
    justify-content: space-between;
    padding: 0 2px;
    width: 175px;
  }
}
@media (max-width: 375px) {
  .formBook aside:first-child label,
  .formBook aside:nth-child(2) label {
    font-size: 0.7em;
  }
  .formBook ul.error_messages.appear {
    margin-bottom: 55px;
  }
}