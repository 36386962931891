.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f1f1f1f1;
  position: relative;
  overflow: hidden;
  padding-bottom: 50px;

  > section:first-child {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px 70px;
    margin: 0;
    width: 100%;
  }

  section:nth-child(2) {
    width: 100%;
  }

  > h3 {
    font-size: 4em;
    font-weight: 600;
    line-height: 1.2;
    color: #212529;
  }

  hr {
    margin: auto;
    width: 80%;
  }

  div[role="figure"] {
    background: #017143;
    background: linear-gradient(141deg, #003f25, #058920 70%, #77ff33);
    clip-path: circle(357px at right 600px);
    height: 100%;
    left: 0;
    position: fixed;
    top: 180px;
    transition: clip-path 1.5s ease-in-out;
    width: 100%;
  }

  div[role="figure"].hide {
    clip-path: circle(0 at right 900px);
  }

  div[role="figure"] span {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  div[role="figure"] .locationActive {
    opacity: 0;
    pointer-events: none;
  }
}

.home.other {
  div[role="figure"] {
    clip-path: circle(500px at right 1000px);
  }
}

// Accueil
.welcome {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 60px auto 0;
  padding-bottom: 60px;
  max-width: 1360px;
  opacity: 0;
  animation: appear 2.5s linear forwards;

  .textBox {
    position: relative;
    max-width: 720px;
    margin-left: 50px;
  }

  .textBox h2 {
    color: #2f2f2f;
    font-size: 2.5em;
    line-height: 1.15em;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .textBox h2 span {
    color: #017143;
    font-size: 1.2em;
    font-weight: 900;
  }

  .textBox p {
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #2f2f2f;
    font-size: 1em;
    font-weight: 400;
    letter-spacing: 0.03em;
  }

  .textBox p span {
    margin-left: 20px;
  }

  .textBox a {
    display: flex;
    align-items: center;

    background-color: #017143;
    color: #fff;
    font-weight: 500;
    border-radius: 40px;
    letter-spacing: 1px;
    text-decoration: none;
    width: 197.5px;
    height: 53px;
    overflow: hidden;
    margin-top: 20px;
    padding: 0 0 0 17.5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
    transition: background-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out, width 0.35s ease-in;
  }

  .textBox a span {
    min-width: 195px;
    margin-left: 0;
  }

  .textBox a::after {
    display: inline-block;
    min-width: 60px;
    min-height: 55px;
    margin-bottom: -5px;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Ccircle cx='32' cy='32' r='30' fill='%239bd84f'/%3E%3Cpath fill='%23fff' d='M33.7 16L49 32L33.7 48V37.4H15V27.1h18.7z'/%3E%3C/svg%3E");
  }

  .textBox a:hover {
    background-color: #0da72c;
    width: 270px;
  }

  .textBox a:active {
    box-shadow: inset rgba(0, 0, 0, 0.2) 0px 2px 10px;
  }

  .imageBox {
    width: auto;
    display: flex;
    justify-content: flex-end;
    margin: 0 0 0 80px;
  }

  .imageBox img {
    margin-top: 10px;
    max-width: 100%;
    border-radius: 40px;
    box-shadow: rgba(0, 0, 0, 0.3) 10px 10px 10px;
    transform: scale3d(1, 1, 1);
    transition: transform 0.5s ease-in-out;
  }

  .imageBox img:hover {
    transform: scale3d(1.05, 1.05, 1.05);
  }
}

// Bandeau
.banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  z-index: 1;
  width: 100%;

  background: linear-gradient(141deg, #017143, #0da72c 70%, #75ff2f);
  box-shadow: rgb(81 81 81 / 14%) 0px 0px 20px 20px;
  opacity: 0.5;
  animation: appear 2.5s linear forwards;

  div {
    display: flex;
    justify-content: space-between;
    margin: auto auto 0;
    max-width: 1400px;
    padding: 60px 0 20px;
    width: 100%;
  }

  div h2 {
    color: #fff;
    font-size: 2.5em;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: 0.04em;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
    text-shadow: 0 0px 25px rgb(0 0 0 / 40%);
  }

  article {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

    width: 100%;
    max-width: 1360px;
    margin: auto;
    padding: 20px 30px 60px;
  }

  article:nth-child(3) {
    padding-bottom: 50px;
  }

  article img {
    max-width: 30%;
    min-width: 280px;
    height: auto;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
    -webkit-box-reflect: below 2px linear-gradient(transparent, #0004);
    transform: scale3d(1, 1, 1);
    transition: transform 0.5s ease-in-out;
  }

  article p {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 1.1em;
    font-weight: 400;
    letter-spacing: 0.05em;
    max-width: 840px;
    margin-left: 40px;
    z-index: 1;
    text-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
  }

  article img:hover {
    transform: scale3d(1.05, 1.05, 1.05);
  }
}

// Pages réglementaires
.welcome.other {
  height: auto;
  margin: 60px auto 100px;
  padding: 0 20px;
  justify-content: center;
  transform: translateY(90vh);
  opacity: 0.5;
  border-bottom: none;
  animation: appear 2.5s linear forwards;

  .textBox {
    max-width: 1140px;
    margin-left: 0;
  }

  .textBox h2 {
    margin-bottom: 60px;
  }
}

.welcome.hideModify {
  animation: none;
  visibility: hidden;
  opacity: 0;
}

.welcome.hide,
.banner.hide {
  overflow: hidden;
  animation: disappear 2.5s linear forwards;
}

// Locations
.locations {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 100px;
  max-width: 1500px;
  margin: -1215px auto auto;
  width: 100%;
  opacity: 0;
  transform: translateY(90vh);

  > li {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    overflow: hidden;
    gap: 10px;
    padding-top: 15px;
    width: 100%;
    margin-bottom: 20px;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(90vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
    height: 100%;
    transform: translateY(1px);
  }
  100% {
    opacity: 0;
    height: 0%;
    transform: translateY(100vh);
    z-index: -1;
  }
}

@keyframes disappearTablet {
  0% {
    opacity: 1;
    height: auto;
    transform: translateY(1px);
  }
  100% {
    opacity: 0;
    height: 0%;
    transform: translateY(100vh);
    z-index: -1;
  }
}

.locations.speed {
  transition: transform 0s ease-out;
}

.locations.appear {
  animation: appear 2s linear forwards;
}

// Locations trouvées
.cardsLoc {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 70px;
  padding-top: 10px;

  .imgBox {
    position: relative;
    width: 100%;
    height: 78%;
    min-height: 400px;
    background-size: cover;
    border-radius: 15px;
  }

  .content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin: 15px 0;
    padding: 20px 0 0 20px;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.16);
  }

  .content > span {
    display: flex;
    position: absolute;
    top: -80px;
    left: 0;
    width: auto;
    height: 65px;
    min-width: 50%;
    border-top: 10px solid #f1f1f1;
    border-right: 10px solid #f1f1f1;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 15px;
    backdrop-filter: blur(5px);
    overflow: hidden;
  }

  .content p {
    display: flex;
    flex-direction: column;
    z-index: 1;
    padding-right: 20px;
  }

  .content p div {
    font-family: "Poppins", sans-serif;
    color: #2f2f2f;
    font-size: 0.95em;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.03em;
    max-height: 315px;
    overflow-y: auto;
  }

  .content p div:nth-child(3),
  .content p div:nth-child(4) {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 1.5s ease-in-out;
    transition-delay: 1.25s;
  }

  .content.contentActive p div:nth-child(3),
  .content.contentActive p div:nth-child(4) {
    height: auto;
    opacity: 1;
  }

  .content h3 {
    color: #fff;
    font-size: 1.375em;
    font-weight: 600;
    padding: 0px 20px 0px;
    text-shadow: 0 0 10px #000;

    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
  }

  .content > ul {
    display: flex;
    gap: 20px;
    height: 100px;
    margin-top: 20px;
    padding: 20px 0;
    position: relative;
  }

  .content > ul div[role="figure"] {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-right: 20px;
    background: linear-gradient(155deg, #017143, #0da72c 70%, #349b00);
    clip-path: ellipse(20% 55% at 90% 70%);
    border-bottom-right-radius: 15px;
    z-index: 0;
    transition: clip-path 0.75s ease-in-out;
  }

  .content > ul div[role="figure"]:hover {
    clip-path: ellipse(22% 60% at 90% 70%);
  }

  .content > ul div[role="figure"] span {
    opacity: 1;
    pointer-events: initial;
    cursor: pointer;
    padding-top: 20px;
    padding-right: 15px;
    letter-spacing: 0.55px;
    font-size: 1.5em;
    font-style: italic;
    font-weight: 600;
    color: #f6f6f6;
    text-shadow: 0 0 20px #00000085;
    transition: color 0.25s ease-in, text-shadow 0.5s ease-in, opacity 0.5s ease-in-out;
  }

  .content > ul div[role="figure"].locationOff span {
    cursor: default;
    margin-right: -5px;
    padding-right: 0;
  }

  .content > ul div[role="figure"]:hover span {
    text-shadow: 0 0 10px #000;
    color: #fff;
  }

  .content > ul div[role="figure"]:active span {
    color: #e9e9e9;
  }

  .content > ul div[role="figure"].locationActive {
    background: linear-gradient(155deg, #017143, #0da72c 70%, #349b00);
    clip-path: ellipse(25% 70% at 90% 70%);
  }

  .content > ul div[role="figure"].locationActive span {
    font-size: 1.2em;
    line-height: 27px;
    font-weight: 400;
    text-align: end;
    color: #fcfcfc;
    text-shadow: none;
    pointer-events: none;
  }

  .content > ul div[role="figure"].locationOff {
    cursor: default;
    background: #c17500;
  }

  .content > ul div[role="figure"].locationActive span strong {
    font-style: normal;
    font-size: 1.9em;
  }

  .content > ul li {
    display: flex;
    align-items: center;
    gap: 10px;

    width: 54px;
    height: 54px;

    overflow: hidden;
    padding: 10px 0 10px 10px;
    z-index: 1;

    border-radius: 2ex;
    background-color: #fff;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.25);

    border-top: 1px solid #9999994f;
    border-right: 1px solid #9999994f;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    transition: width 0.5s ease-in;
  }

  .content > ul li:hover {
    width: 220px;
    border-bottom: 1px solid #9999994f;
    border-left: 1px solid #9999994f;
  }

  .content > ul li span {
    opacity: 0;
    overflow: hidden;
    width: 100%;
    text-align: center;
    font-size: 0.9em;
    font-weight: 600;
    color: #2f2f2f;
    transition: 0.25s opacity 0.5s ease-in;
  }

  .content > ul li:hover span {
    opacity: 1;
  }

  .content > ul li svg {
    min-width: 32px;
    min-height: 32px;
  }
}

.locations .thumbails {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
  height: fit-content;
  width: 50%;
  padding-bottom: 20px;
  padding-top: 10px;
  max-height: 950px;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: block;
  }

  ::-webkit-scrollbar {
    background: hsl(0deg 0% 41% / 83%);
    width: 5px;
  }

  li {
    list-style: none;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: rgb(0 0 0 / 14%) 1px 5px 5px;
    border-radius: 10px;
    width: 250px;
    height: 180px;
    margin: 0 5px;
    cursor: pointer;
    transform: scale3d(1, 1, 1);
    transition: transform 0.5s ease-in-out;
  }

  li:hover {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  span[role="previous"],
  span[role="next"] {
    display: none;
  }
}

.locations .thumbails::-webkit-scrollbar {
  display: block;
  width: 5px;
}

.locations .thumbails::-webkit-scrollbar-thumb {
  background: hsl(0deg 0% 41% / 83%);
}

@media (min-width: 1921px) {
  .locations .thumbails {
    width: 40%;
  }

  .cardsLoc {
    margin-left: 20px;
  }
}

@media (max-width: 1920px) {
  .locations .thumbails {
    justify-content: flex-start;
    width: 40%;
  }
}

@media (max-width: 1800px) {
  .welcome {
    .textBox {
      margin-left: 0;
    }
  }

  .cardsLoc {
    .content > ul div[role="figure"].locationOff span {
      margin-right: -10px;
    }
  }
}

@media (max-width: 1500px) {
  .welcome,
  .banner,
  .cardsLoc {
    padding-left: 20px;
    padding-right: 20px;
  }

  .locations {
    > li {
      justify-content: center;
      gap: 10px;
    }
  }

  .locations .thumbails {
    justify-content: flex-end;
    width: 45%;

    li {
      height: 175px;
      width: 235px;
    }
  }

  .cardsLoc {
    margin-left: 0;

    .content > ul div[role="figure"] {
      clip-path: ellipse(20% 55% at 90% 70%);
    }

    .content > ul div[role="figure"] span {
      padding-top: 17px;
    }

    .content > ul div[role="figure"].locationOff span {
      font-size: 1.5em;
    }

    .content > ul div[role="figure"].locationActive span strong {
      font-size: 1.5em;
    }
  }
}

@media (max-width: 1290px) {
  .banner.hide,
  .welcome.hide {
    overflow: hidden;
    animation: disappearTablet 2s linear forwards;
  }

  .welcome {
    flex-direction: column;
    align-items: center;

    .textBox {
      max-width: 950px;
      margin-bottom: 50px;
    }

    .imageBox img {
      max-width: 600px;
    }
  }

  .locations {
    justify-content: center;
    margin: auto;

    > li {
      justify-content: space-between;
      position: relative;
      padding-top: 0;
      max-height: 875px;
      width: 100%;
      gap: 20px;
    }
  }

  .cardsLoc {
    max-width: 775px;
    width: auto;

    .content > ul {
      margin-top: 20px;
    }

    .content > ul div[role="figure"] span {
      padding-top: 20px;
      padding-right: 10px;
    }
  }

  .locations .thumbails {
    position: relative;
    overflow-y: hidden;
    justify-content: center;
    align-self: center;
    min-width: 300px;
    height: 95%;
    width: 25%;
  }

  .locations span[role="previous"],
  .locations span[role="next"] {
    display: inline-block;
    width: 265px;
    height: 35px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 17.5px;
    z-index: 5;
    overflow: hidden;
    border-radius: 10px;
    background-color: hsla(0, 0%, 57%, 0.45);
    backdrop-filter: blur(10px) contrast(0.5);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px;
    transition: backdrop-filter 0.25s ease-in-out, box-shadow 0.7s ease-in, background-color 0.5s ease-out;
  }

  .locations span[role="previous"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024'%3E%3Cpath fill='%23fff' d='M8.2 751.4c0 8.6 3.4 17.401 10 24.001c13.2 13.2 34.8 13.2 48 0l451.8-451.8l445.2 445.2c13.2 13.2 34.8 13.2 48 0s13.2-34.8 0-48L542 251.401c-13.2-13.2-34.8-13.2-48 0l-475.8 475.8c-6.8 6.8-10 15.4-10 24.2z'/%3E%3C/svg%3E");
    top: 0;
  }

  .locations span[role="next"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024'%3E%3Cpath fill='%23fff' d='M8.2 275.4c0-8.6 3.4-17.401 10-24.001c13.2-13.2 34.8-13.2 48 0l451.8 451.8l445.2-445.2c13.2-13.2 34.8-13.2 48 0s13.2 34.8 0 48L542 775.399c-13.2 13.2-34.8 13.2-48 0l-475.8-475.8c-6.8-6.8-10-15.4-10-24.199'/%3E%3C/svg%3E");
    top: 93.5%;
  }

  .locations span[role="previous"]:hover,
  .locations span[role="next"]:hover {
    backdrop-filter: blur(20px) contrast(0.25);
    background-color: rgb(51 51 51 / 29%);
    cursor: pointer;
  }

  .locations span[role="previous"]:active,
  .locations span[role="next"]:active {
    box-shadow: inset rgba(0, 0, 0, 0.3) 0px 0px 2px;
    background-color: rgb(51 51 51 / 29%);
  }
}

@media (max-width: 1000px) {
  .locations {
    > li {
      justify-content: center;
    }
  }

  .welcome {
    .imageBox img {
      max-width: 450px;
    }
  }

  .banner {
    div {
      justify-content: center;
    }

    div h2 {
      text-align: center;
    }

    article {
      justify-content: space-evenly;
    }

    article p {
      margin-left: 0;
    }
  }

  .locations span[role="previous"],
  .locations span[role="next"],
  .locations:not(.choiceActive) .thumbails {
    display: none;
  }

  .locations.choiceActive {
    > li {
      flex-direction: column;
      max-height: none;
      gap: 50px;
    }

    .cardsLoc {
      max-width: none;
    }

    .content > ul div[role="figure"].locationActive {
      clip-path: ellipse(20% 70% at 90% 70%);
    }

    .thumbails {
      width: auto;
      padding-bottom: 30px;
      max-height: 500px;
      overflow-y: auto;
      gap: 10px;
    }

    .thumbails li {
      height: 150px;
      width: 210px;
    }

    .thumbails::-webkit-scrollbar {
      display: block;
      width: 5px;
    }

    .thumbails::-webkit-scrollbar-thumb {
      background-color: #8e8e8e;
    }

    .thumbails.moreCover + span[role="next"] {
      display: inline-block;
      overflow: hidden;
      border-radius: 100px;
      box-shadow: none;
      backdrop-filter: none;
      background-image: none;
      background: rgb(255, 255, 255);
      background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 95%, 0.824) 25%, #f0f0f0e6);
      width: -webkit-fill-available;
      top: 97.5%;
    }
  }
}

@media (max-width: 870px) {
  .welcome {
    flex-direction: column;

    .textBox p {
      align-items: flex-end;
      font-size: 0.875em;
      color: #000;
    }

    .textBox a {
      height: 38px;
      width: 217px;
      margin-right: 20px;
    }

    .textBox a span {
      min-width: 161px;
    }

    .textBox a:after {
      min-height: 20px;
      min-width: 40px;
    }

    .textBox a:hover {
      width: 265px;
      background-color: #017143;
    }

    .imageBox {
      margin: 50px auto 0;
    }
  }

  .welcome.other {
    .textBox p {
      align-items: flex-start;
    }
  }

  .locations.choiceActive {
    .cardsLoc {
      .content > ul {
        align-items: center;
      }

      .content > ul div[role="figure"].locationActive {
        clip-path: ellipse(25% 60% at 95% 70%);
      }
    }
  }
}

@media (max-width: 768px) {
  .home {
    background-color: #fff;
    margin: 0 auto;
  }

  .welcome {
    .textBox {
      margin-bottom: 50px;
    }
  }

  .banner {
    div {
      padding: 30px 0 0px;
    }

    div h2 {
      font-size: 2em;
      margin-bottom: 50px;
      margin-top: 10px;
    }

    article {
      gap: 40px;
    }

    article p {
      align-items: flex-end;
      font-size: 0.9em;
    }
  }

  .welcome {
    .textBox h2 {
      font-size: 2em;
      padding-left: 20px;
      margin-top: 0;
      margin-bottom: 25px;
    }
  }

  .welcome.other {
    .textBox h2[role="term"] {
      white-space: nowrap;
    }

    .textBox p span {
      margin-left: 0;
    }
  }

  .locations {
    > li {
      max-height: none;
    }
  }

  .locations.choiceActive {
    .thumbails.moreCover + span[role="next"] {
      background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsl(0deg 0% 100%) 25%, #fff);
    }

    .cardsLoc {
      .content {
        box-shadow: none;
        border-radius: 12.5px;
        padding: 20px 0 0;
        margin: 0;
      }

      .content > ul {
        height: auto;
        padding: 10px 0;
      }

      .content > ul,
      .content p div {
        padding-left: 20px;
      }

      .content p {
        font-size: 0.9em;
        max-height: 160px;
        overflow-y: auto;
      }

      .content p::-webkit-scrollbar {
        display: block;
        width: 5px;
      }

      .content p::-webkit-scrollbar-thumb {
        background-color: #8e8e8e;
      }

      .content > ul div[role="figure"].locationActive {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        clip-path: ellipse(30% 65% at 88% 65%);
        padding-right: 0;
      }

      .content > ul div[role="figure"].locationActive:hover {
        clip-path: ellipse(30% 65% at 88% 65%);
      }

      .content > ul div[role="figure"] span {
        display: flex;
        gap: 7.5px;
        font-size: 1.5em;
        padding-left: 0;
        padding-top: 0;
      }

      .content > ul div[role="figure"] span strong {
        font-size: 1.25em;
      }

      .content > ul div[role="figure"] span br {
        display: none;
      }

      .content > span {
        border-top-right-radius: 20px;
        height: 55px;
        top: -55px;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation: portrait) {
  .welcome {
    .imageBox img {
      max-width: 450px;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation: landscape) {
  .welcome {
    .imageBox img {
      max-width: 500px;
    }
  }
}

@media (max-width: 620px) {
  .locations {
    transform: translateY(-15px);
    margin-top: -30px;
    gap: 50px;

    > li {
      box-shadow: 0 18px 22px hsl(0deg 0% 87.29% / 59%);
      border-radius: 7.5px;
    }
  }

  .home {
    section:nth-child(2) {
      border-bottom: none;
    }
  }

  .banner {
    article {
      padding: 20px 0px 60px;
    }
  }

  .home,
  .home.other {
    div[role="figure"] {
      clip-path: circle(385px at right 1050px);
      background: linear-gradient(155deg, #017143, #0da72cfa 70%, #75ff2fd6);
    }
  }

  .home.other {
    div[role="figure"] {
      top: 200px;
    }
  }

  .welcome.other {
    margin: 45px auto;

    .textBox h2 {
      margin-top: 0;
      margin-bottom: 35px;
    }
  }

  .welcome {
    margin: 25px auto 0;

    .textBox p,
    .textBox h2 {
      padding-left: 0;
      margin-left: 7.5px;
      margin-right: 7.5px;
    }

    .textBox p {
      font-size: 0.85em;
    }

    .textBox p span {
      margin-left: 0;
    }

    .imageBox {
      margin: 0 auto 0;
    }

    .imageBox img {
      max-width: 90%;
      min-width: 100%;
    }
  }

  .locations.choiceActive .cardsLoc,
  .cardsLoc {
    .imgBox {
      border-radius: 7.5px;
      min-height: 225px;
    }

    .content {
      box-shadow: none;
      padding: 0;
      margin: 0;
    }

    .content p {
      padding-right: 5px;
      max-height: 0;
      overflow-y: hidden;
    }

    .content h3 {
      font-size: 1.1em;
      padding: 0 10px;
    }

    .content > ul {
      height: auto;
      padding: 10px 0;
      margin-top: 0;
      gap: 0;
    }

    .content > ul li {
      height: 35px;
      width: 35px;
    }

    .content > ul {
      gap: 10px;
      padding-top: 10px;
      padding-left: 20px;
      border-top: 1px solid hsl(0deg 0% 60% / 19%);
    }

    .content p div {
      padding-left: 10px;
    }

    .content p div::-webkit-scrollbar {
      display: block;
      width: 4px;
    }

    .content p div::-webkit-scrollbar-thumb {
      background-color: #999;
    }

    .content > ul div[role="figure"] {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      padding-right: 0;
      background: none;
      clip-path: none;
      overflow: hidden;
    }

    .content > ul div[role="figure"].locationActive,
    .content > ul div[role="figure"].locationActive:hover,
    .content > ul div[role="figure"]:hover {
      clip-path: none;
    }

    .content > ul div[role="figure"].locationActive span,
    .content > ul div[role="figure"].locationOff span,
    .content > ul div[role="figure"] span {
      background: linear-gradient(-180deg, #00a314, #0da72c 30%, #2fab18);
      padding: 9px 12.5px 7.5px;
      margin-right: 20px;
      border-radius: 40px;
      font-size: 1em;
      font-weight: 500;
    }

    .content > ul div[role="figure"].locationOff {
      background: none;
    }

    .content > ul div[role="figure"].locationOff span {
      background: #c17500;
    }

    .content > ul div[role="figure"].locationActive span {
      align-items: center;
      gap: 8px;
    }

    .content > ul div[role="figure"].locationActive span strong {
      font-size: 1.1em;
      font-weight: 600;
      font-style: italic;
    }

    .content > ul div[role="figure"]:hover span {
      text-shadow: none;
    }

    .content > ul li {
      padding: 10px 0 10px 5px;
      height: 40px;
      width: 40px;
    }

    .content > ul li svg {
      min-width: 27px;
      min-height: 27px;
    }

    .content > span {
      border-bottom-left-radius: 5px;
      border-top: 5px solid #f1f1f1;
      border-right: 5px solid #f1f1f1;
      border-top-right-radius: 20px;
      min-width: 0;
      height: 45px;
      top: -45px;
    }
  }

  .locations.choiceActive {
    .cardsLoc {
      .content p {
        padding-right: 10px;
        padding-bottom: 10px;
        margin: 20px 0;
        max-height: 200px;
        overflow-y: auto;
      }

      .content > ul {
        padding-top: 20px;
      }

      .content > ul div[role="figure"].locationActive {
        top: 5px;
      }
    }

    > li {
      box-shadow: none;
    }

    .thumbails {
      max-height: 300px;
      gap: 7.5px;
    }

    .thumbails li {
      height: 130px;
      width: 190px;
    }

    .thumbails.moreCover + span[role="next"] {
      width: 100%;
      left: 10px;
    }
  }
}

@media (max-width: 515px) {
  .home {
    padding-left: 0;
    padding-right: 0;

    section[role="main"] {
      width: auto;
    }
  }

  .locations.choiceActive {
    .thumbails li {
      height: 110px;
      width: 170px;
    }
  }

  .cardsLoc {
    .content > ul li {
      box-shadow: none;
      border: none;
    }

    .content > ul li:hover {
      width: 40px;
      border-bottom: none;
      border-left: none;
    }

    .content > ul li:hover span {
      opacity: 0;
    }

    .content > ul div[role="figure"].locationActive span,
    .content > ul div[role="figure"].locationOff span,
    .content > ul div[role="figure"] span {
      margin-right: 5px;
    }

    .content > ul {
      padding-left: 10px;
    }
  }

  .locations.choiceActive {
    .cardsLoc {
      .content > ul {
        gap: 10px;
      }
    }
  }
}

@media (max-width: 375px) {
  .welcome,
  .welcom.other {
    margin: 25px auto;

    .textBox h2 {
      font-size: 1.9em;
      margin-bottom: 40px !important;
    }

    .textBox p span {
      margin-left: 0;
    }
  }

  .cardsLoc {
    .content p div {
      padding-left: 10px;
    }

    .content > ul div[role="figure"] {
      padding-right: 10px;
    }

    .content > ul div[role="figure"].locationActive span,
    .content > ul div[role="figure"].locationOff span,
    .content > ul div[role="figure"] span {
      margin-right: 0;
    }

    .content > ul li svg {
      width: 1.5em;
      height: 1.5em;
    }
  }
}
