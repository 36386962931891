.formBook {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  width: 54vw;
  height: 100%;
  max-width: 1140px;

  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px;
  backdrop-filter: blur(20px) brightness(0.75);
  transition: 0.5s ease;
  padding: 0 10px;

  aside {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 0.9vw;
    padding: 13px 0;
    height: auto;
    transition: transform 0.75s ease-in-out, height 0.5s ease-in;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  aside:first-child label {
    display: flex;
    align-items: center;

    color: #2d2d2d;
    font-size: 0.75em;
    letter-spacing: 0.07em;
    position: absolute;

    width: 100%;
    height: 100%;

    background-color: white;

    cursor: pointer;
    pointer-events: none;
    transition: color 0.25s ease-in-out, background-color 0.25s ease-in;
  }

  aside:first-child div {
    border-radius: 10px;
    overflow: hidden;
  }

  aside:nth-child(2) label {
    font-size: 0.9em;
    text-shadow: 0 0px 10px rgb(0 0 0 / 42%);
    color: whitesmoke;
    letter-spacing: 0.07em;
    cursor: pointer;
    transition: color 0.25s ease-in-out, background-color 0.25s ease-in;
  }

  aside:first-child div,
  aside:nth-child(2) div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    position: relative;
  }

  aside:nth-child(2) div {
    cursor: pointer;
  }

  aside:nth-child(2) {
    padding: 10px 0 20px;
  }

  select,
  input:not(input[type="checkbox"]) {
    display: block;
    width: 100%;
    min-height: 35.5px;
    min-width: 190px;
    padding: 0.3rem 0.75rem;
    font-size: 0.9em;
    font-weight: 400;
    line-height: 1.5;
    font-family: Lato, sans-serif;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #fff;
    outline: none;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    transition: border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out,
      border-radius 0.5s ease-in-out;
  }

  aside:first-child select:focus + label,
  aside:first-child input:focus + label {
    color: transparent;
    background-color: transparent;
  }

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;

    background-color: hsl(0deg 0% 96% / 86%);
    border-radius: 5px;
    width: 17.5px;
    height: 17.5px;
    transition: background-color 0.25s ease-in-out;
  }

  input[type="checkbox"]:checked {
    z-index: 1;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23666' d='M12 7a5 5 0 1 1-4.995 5.217L7 12l.005-.217A5 5 0 0 1 12 7'/%3E%3C/svg%3E");
    width: 17.5px;
    height: 17.5px;
    background-color: #fff;
  }

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4.5px;

    padding: 0.375rem 1em;
    font-size: 0.9em;
    font-weight: 400;
    letter-spacing: 0.08em;
    color: #fff;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 20px;
    background-color: #464646;
    min-width: 126px;
    min-height: 44px;
    cursor: pointer;
    transition: background-color 0.25s ease-in;
  }

  button:disabled {
    background-color: #21252996;
    color: #f9f9f9;
    cursor: default;
  }

  button:disabled:hover {
    filter: brightness(1);
  }

  > ul {
    justify-content: center !important;
  }

  ul div {
    border-left: none;
  }

  ul.error_messages {
    height: 0;
    margin-bottom: 0;
    transition: height 0.75s ease-in-out, margin-bottom 0.75s ease-in-out;
  }

  ul.error_messages.appear {
    height: 25px;
    margin-bottom: 20px;
    transition: height 0.5s ease-in, margin-bottom 0.5s ease-in-out;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    background-color: #c17500;
    border-radius: 20px;
    padding: 7.5px 12.5px;

    color: #fff;
    font-weight: 500;
    font-size: 0.9em;
    letter-spacing: 1px;
  }
}

@media (max-width: 1800px) {
  .formBook {
    width: 100%;
  }
}

@media (max-width: 780px) {
  .formBook {
    aside:nth-child(2) {
      justify-content: space-evenly;
    }

    aside:nth-child(2) div label {
      font-size: 0.8em;
    }

    aside:first-child label {
      font-size: 0.75em;
      color: #666666e8;
    }
  }
}

@media (min-width: 621px) {
  .formBook {
    menu {
      display: none;
    }

    aside:nth-child(2) {
      height: auto;
      transform: translateY(1px);
    }
  }
}

@media (max-width: 620px) {
  .formBook {
    box-shadow: none;
    backdrop-filter: none;

    aside:first-child {
      padding-bottom: 0;
      justify-content: space-around;
    }

    aside:first-child label {
      padding-left: 5px;
      color: #666666e8;
      font-weight: 500;
    }

    aside:first-child div {
      width: 47%;
      overflow: hidden;
      margin-bottom: 2.5px;
    }

    aside:first-child div:first-child {
      order: 1;
    }

    aside:first-child div:nth-child(2) {
      order: 3;
    }

    aside:first-child div:nth-child(3) {
      order: 4;
    }

    aside:first-child div:nth-child(4) {
      order: 2;
    }

    aside:nth-child(2) {
      height: 0;
      padding: 0;
      overflow: hidden;
      transform: translateY(200px);
    }

    aside:nth-child(2).active {
      height: 70px;
      padding: 0 13px;
      transform: translateY(1px);
    }

    menu {
      height: 30px;
      width: 30%;
      color: #fff;
      list-style: none;
      margin-top: -25px;
      z-index: 2;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M12 14.702L6.692 9.394l.708-.707l4.6 4.6l4.6-4.6l.708.707z'/%3E%3C/svg%3E");
    }

    aside:nth-child(2).active + menu {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='m7.4 14.702l-.708-.708L12 8.687l5.308 5.307l-.708.708l-4.6-4.6z'/%3E%3C/svg%3E");
      margin-top: 5px;
    }

    button,
    button:disabled {
      order: 5;
      width: 15%;
      max-width: none;
      min-width: 65px;
      margin-left: auto;
      font-size: 0;
      color: transparent;
      background-color: transparent;
      padding-top: 0;
    }

    select,
    input:not(input[type="checkbox"]) {
      min-height: 30px;
      font-size: 0.75em;
    }

    ul.error_messages.appear {
      margin-bottom: 45px;
    }
  }
}

@media (max-width: 515px) {
  .formBook {
    select,
    input:not(input[type="checkbox"]) {
      min-width: 0;
    }

    aside:nth-child(2) div {
      flex-grow: 1;
    }

    aside:nth-child(2) div label {
      font-weight: 400;
    }

    aside:nth-child(2) {
      gap: 7.5px;
    }

    aside:nth-child(2) div {
      justify-content: space-between;
      padding: 0 2px;
      width: 175px;
    }
  }
}

@media (max-width: 375px) {
  .formBook {
    aside:first-child label,
    aside:nth-child(2) label {
      font-size: 0.7em;
    }

    ul.error_messages.appear {
      margin-bottom: 55px;
    }
  }
}
