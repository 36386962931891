.activities_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 80px auto 60px auto;
  max-width: 1360px;
  z-index: 1;
  transform: translateY(90vh);
  opacity: 0.5;
  animation: appear 2.5s linear forwards;
}
.activities_container > h2 {
  color: #2f2f2f;
  font-size: 2.5em;
  line-height: 1.15em;
  font-weight: 600;
  margin-bottom: 20px;
  margin-left: 50px;
}
.activities_container > h2 span {
  color: #017143;
  font-size: 1em;
  font-weight: 900;
}
.activities_container > p {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #2f2f2f;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 0.03em;
}
.activities_container > p span {
  margin-left: 20px;
  margin-left: 70px;
  max-width: 800px;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(90vh);
  }
  100% {
    opacity: 1;
    transform: translateY(1px);
  }
}
.activities {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 60px auto;
  max-width: 1360px;
}
.activities article {
  position: relative;
  max-width: 330px;
  height: 300px;
  background-color: #f1f1f1;
  margin: 55px 10px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  transition: 0.5s ease-in-out;
}
.activities a {
  color: #2f2f2f;
  font-family: Poppins, sans-serif;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 0.03em;
  text-decoration: none;
}
.activities article:hover {
  height: 500px;
  transition-delay: 0.2s;
}
.activities a[role=link] {
  position: relative;
  display: block;
  width: 260px;
  height: 260px;
  top: -60px;
  left: 20px;
  z-index: 1;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}
.activities a[role=link] img {
  max-width: 100%;
  border-radius: 4px;
}
.activities a[role=link] h2 {
  font-size: 1.2em;
  text-align: center;
  margin-top: 20px;
  margin-left: -20px;
  width: 300px;
  font-weight: 500;
}
.activities a[role=presentation] {
  position: relative;
  display: block;
  margin-top: -140px;
  margin-left: -5px;
  padding: 10px 15px;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  width: 310px;
  transition: 0.5s ease-in-out;
}
.activities :hover a[role=presentation] {
  visibility: visible;
  opacity: 1;
  margin-top: -5px;
  transition-delay: 0.5s;
}

@media (max-width: 1800px) {
  .activities_container > h2 {
    margin-left: 0;
  }
  .activities_container > p span {
    margin-left: 20px;
  }
}
@media (max-width: 1500px) {
  .activities_container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 1290px) {
  .activities_container {
    max-width: 950px;
  }
}
@media (max-width: 870px) {
  .activities_container p {
    color: #000;
    font-size: 0.875em;
  }
  .activities article {
    align-items: center;
    max-width: 300px;
    height: 450px;
  }
  .activities a[role=link] {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    box-shadow: none;
    width: 230px;
    left: auto;
  }
  .activities a[role=link] img {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  }
  .activities a[role=link] h2 {
    margin-left: 0;
    width: 115%;
  }
  .activities a[role=presentation] {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    margin-left: 0;
    opacity: 1;
    visibility: visible;
    width: auto;
    top: -60px;
  }
  .activities article:hover {
    height: 450px;
  }
}
@media (max-width: 768px) {
  .activities_container > h2 {
    font-size: 2em;
    margin-bottom: 50px;
    margin-top: 10px;
    padding-left: 20px;
  }
}
@media (max-width: 620px) {
  .activities_container {
    margin: 30px auto 0 auto;
  }
  .activities_container > h2 {
    padding-left: 0;
  }
  .activities_container > p,
  .activities_container > h2 {
    margin-left: 7.5px;
    margin-right: 7.5px;
  }
  .activities_container > p span {
    margin-left: 0;
  }
}
@media (max-width: 375px) {
  .activities article,
  .activities article:hover {
    height: 460px;
  }
  .activities a[role=link] h2 {
    width: 100%;
  }
}