.formLog {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1d4;
  width: 100%;
  height: 100vh;
  min-height: 768px;
  transition: 1s;
}

.formLog.formSlide {
  .formBox {
    left: 50%;
  }

  .formBox .formSignIn {
    top: -100%;
    transition-delay: 0s;
  }

  .formBox .formSignUp {
    top: 0;
    transition-delay: 1s;
  }
}

// Reset password
.formBox .formSignIn p {
  color: #2f2f2f;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 25px;
  border-bottom: 1px solid #999;
  padding-bottom: 2px;
  width: fit-content;
  transition: color 0.5s ease-out;
}

.formBox .formSignIn p:hover {
  color: #696969;
}

.formContainer {
  position: relative;
  width: 60vw;
  height: 65vh;
  max-height: 600px;
  max-width: 1140px;
  backdrop-filter: blur(20px) brightness(0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.formContainerBox {
  position: relative;
  width: 50%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;

  h2 {
    color: #fff;
    letter-spacing: 1px;
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 25px;
    text-shadow: 3px 2px 30px #000;
  }

  button {
    cursor: pointer;
    padding: 10px 20px;
    color: #fff;
    font-size: 1.1em;
    font-weight: 500;
    letter-spacing: 1px;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px;
    transition: box-shadow 0.25s ease-in-out;
    background: linear-gradient(155deg, #0f9310, #0ea32c 70%, #43a70f);
  }

  button:active {
    box-shadow: inset rgba(0, 0, 0, 0.3) 0px 0px 2px;
  }
}

.formContainerBox:nth-child(2) > button {
  background: linear-gradient(155deg, #e30000, #d40000 70%, #d60202);
}

.formBox {
  position: absolute;
  left: 10%;
  width: 25vw;
  height: 75vh;

  max-width: 515px;
  min-width: 515px;

  max-height: 750px;
  min-height: 500px;
  background-color: #fff;

  z-index: 1000;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 25px;
  transition: 0.5s;
  transition-delay: 0.5s;

  .formSignIn,
  .formSignUp {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    max-width: 515px;
    max-height: 800px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: #fff;

    transition: 0.5s;
  }

  .formSignUp {
    top: 100%;
    transition-delay: 0s;
  }

  .formSignIn {
    top: 0;
    transition-delay: 1s;
  }

  .formSignIn span.error {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;

    background-color: #c10000;
    border-radius: 5px;
    padding: 8px 12.5px;

    color: #fff;
    font-size: 1em;
    margin-bottom: 20px;
  }

  .formSignIn span.error svg {
    min-width: 30px;
    min-height: 30px;
  }

  .formSignIn form,
  .formSignUp form {
    display: flex;
    flex-direction: column;
    padding: 0 60px;
    width: 100%;
    overflow-y: auto;
  }

  .formSignIn h3,
  .formSignUp h3 {
    font-size: 1.375em;
    font-weight: 600;
    padding: 0 50px;
    margin-bottom: 40px;
    color: #2f2f2f;
  }

  .formSignIn form input,
  .formSignUp form input {
    width: 100%;
    min-width: 300px;
    min-height: 35px;
    margin-bottom: 15px;
    padding: 10px;
    outline: none;
    flex: none;
    font-size: 0.9em;
    letter-spacing: 0.1em;
    background-color: #e9e9e9;
    border-radius: 0;
    box-shadow: none;
    border: none;
  }

  .formSignIn form input::placeholder,
  .formSignUp form input::placeholder {
    color: #222;
    font-weight: 400;
    text-transform: capitalize;
    font-size: 0.9em;
  }

  .formSignIn form button,
  .formSignUp form button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    background: linear-gradient(155deg, #0f9310, #0ea32c 70%, #43a70f);
    border: none;
    color: #fff;

    padding: 5px 10px 5px 15px;
    max-width: 180px;
    width: fit-content;
    min-height: 40px;
    cursor: pointer;
    letter-spacing: 1px;
    font-size: 1em;
    font-weight: 500;
    margin-top: 20px;
    border-radius: 5px;
    box-shadow: none;
  }

  .formSignUp button:disabled,
  .formSignIn form button:disabled {
    background: #999;
    color: #f9f9f9;
    cursor: default;
  }

  .formSignUp button:hover,
  .formSignIn form button:hover {
    filter: brightness(1);
  }

  .formSignUp form button:not(:disabled):active,
  .formSignIn form button:not(:disabled):active {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  }
}

@media (max-width: 1920px) {
  .formContainer {
    width: 100%;
  }

  .formBox {
    width: 27vw;
  }

  .formLog.formSlide {
    .formBox {
      left: 52%;
    }
  }
}

@media (max-width: 1280px) {
  .formContainer {
    max-height: 575px;
    max-width: 940px;

    h2 {
      font-size: 1.05em;
    }

    button {
      font-size: 1em;
    }
  }

  .formBox {
    left: 5%;

    min-width: 430px;
    max-height: 700px;

    .formSignIn form,
    .formSignUp form {
      padding: 0 30px;
    }

    .formSignIn form h3,
    .formSignUp form h3 {
      padding: 0 20px;
    }

    .formSignIn form button,
    .formSignUp form button {
      font-size: 1em;
    }
  }
}

@media (max-width: 1000px) {
  .formLog {
    background-color: transparent;
    border-bottom: 20px solid white;
  }

  .formContainer {
    max-height: none;
    max-width: none;
    height: 100%;
  }

  .formLog.formSlide {
    .formBox {
      left: 47.5vw;
    }
  }
}

@media (max-width: 870px) {
  .formContainer {
    flex-direction: row;
    flex-wrap: wrap;

    h2 {
      display: none;
    }
  }

  .formContainerBox {
    justify-content: flex-end;
    margin: auto;
    padding: 40px 0;
    width: auto;

    h2 {
      font-weight: 500;
    }
  }

  .formBox {
    left: auto;
  }

  .formLog.formSlide {
    .formBox {
      left: auto;
    }
  }
}

@media (max-width: 620px) {
  .formBox .formSignIn p {
    font-weight: 400;
  }

  .formBox {
    .formSignIn form,
    .formSignUp form {
      padding: 0 20px;
      border-bottom: 1px solid #999999c7;
    }
  }
}

@media (max-width: 515px) {
  .formLog {
    gap: 5px;
  }

  .formBox {
    max-width: 100vw;
    min-width: 100vw;

    max-height: none;
    height: 100vh;
    z-index: 0;

    .formSignIn,
    .formSignUp {
      max-height: none;
    }

    .formSignIn h3,
    .formSignUp h3 {
      margin: 0 0 20px;
      padding: 0 25px 20px;
    }

    .formSignIn form input,
    .formSignUp form input {
      max-width: 360px;
    }
  }

  .formContainer {
    align-items: flex-end;

    form button {
      margin-top: 0 !important;
      margin-bottom: 4.5vh;
    }
  }

  .formContainerBox {
    height: auto;
    margin: 0 auto;
    padding: 0;

    button {
      margin-bottom: 13.5vh;
    }
  }
}

@media (max-width: 375px) {
  .formLog {
    min-height: 760px;
  }

  .formContainer {
    align-items: flex-start;

    form button {
      margin-bottom: 5vh;
    }
  }

  .formBox {
    .formSignIn h3,
    .formSignUp h3 {
      padding: 15px 25px 5px;
    }

    .formSignIn form button,
    .formSignUp form button {
      margin-top: 0;
    }
  }

  .formContainerBox {
    height: 105vh;
  }
}
