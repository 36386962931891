.header {
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 21vh;
  min-height: 250px;
  width: 100%;
  z-index: 3;
  transition: height 0.75s ease-in-out, min-height 0.75s ease-in-out,
    overflow 0.75s ease-in-out;
  transition-delay: 0.25s;
}

.header.hide {
  height: 0;
  min-height: 0;
  padding: 0;
  overflow: hidden;
}

@media (max-width: 1280px) {
  .header {
    height: 35vh;
  }
}

@media (max-width: 1000px) {
  .header {
    height: auto;
    justify-content: flex-end;
    padding-top: 45px;
    z-index: 2;
  }
}

@media (max-width: 620px) {
  .header {
    backdrop-filter: blur(0px) brightness(.8);
  }
}

@media (max-width: 515px) {
  .header {
    padding-top: 30px;
  }
}
