.reassurance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;

  background-image: linear-gradient(
      90deg,
      rgba(26, 100, 8, 1) 0%,
      rgba(150, 184, 141, 0) 100%,
      rgba(255, 255, 255, 0) 100%
    ),
    url(/htdocs/images/assets/cours-gite.jpg);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  min-height: 120px;
  padding: 20px 15vw;
  width: 100%;
  z-index: 4;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 30px 0 30px 50px;
  }

  div h3 {
    color: #f1f1f1;
    text-transform: uppercase;
    font-family: Lato, sans-serif;
    font-size: 3em;
    text-align: start;
    line-height: 55px;
    font-weight: 900;
    padding-bottom: 20px;
    border-bottom: 12.5px solid #75ff2f;
    border-bottom-right-radius: 10px;
    width: 100%;
  }

  div address {
    color: #fff;
    font-size: 1.1em;
    font-style: normal;
    letter-spacing: 0.08em;
    line-height: 27px;
    text-shadow: 0 0px 10px rgba(0, 0, 0, 0.6);
  }

  iframe {
    border: 0;
    height: 35vh;
    width: 30vw;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 2px;
    border-radius: 20px;
    max-width: 520px;
    max-height: 260px;
  }
}

.footer {
  background-color: #f1f1f1;
  width: 100%;
  height: auto;
  padding: 20px 100px 10px;
  position: relative;
  z-index: 4;
}

.footerContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-gap: 60px;
  max-width: 1360px;
  margin: auto;
}

.footerSecAboutUs {
  h2 {
    font-size: 1.375em;
    position: relative;
    color: #2f2f2f;
    font-weight: 600;
    margin-bottom: 15px;
  }

  p {
    color: #2f2f2f;
    line-height: 30px;
    font-size: 0.9em;
  }
}

.footerSocialLinks {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 50px);
  gap: 20px;

  li {
    display: flex;
    justify-content: center;
    align-items: center;

    list-style: none;
    border-radius: 5px;
    padding: 7.5px 7.5px 4.5px;
    border: 1px solid transparent;
    transition: border 0.5s ease-in;
  }

  li:hover {
    border-color: #999999a9;
  }

  li:active {
    box-shadow: inset rgba(0, 0, 0, 0.3) 0px 0px 2px;
  }
}

.footerQuickLinks {
  position: relative;

  h2 {
    font-family: Lato, sans-serif;
    position: relative;
    color: #2f2f2f;
    font-size: 1.375em;
    font-weight: 600;
    margin-bottom: 15px;
  }

  ul li {
    list-style: none;
  }

  ul li a {
    color: #2f2f2f;
    text-decoration: none;
    font-size: 0.9em;
    display: inline-block;
    margin-bottom: 7.5px;
    padding-bottom: 5px;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.3s ease-in;
  }

  ul li a:hover {
    border-color: #555;
  }
}

.footerContact {
  h2 {
    position: relative;
    color: #2f2f2f;
    font-weight: 600;
    font-size: 1.375em;
    margin-bottom: 15px;
  }

  ul {
    position: relative;
  }

  ul li {
    margin-bottom: 15px;
    list-style: none;
  }

  ul li a {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #2f2f2f;
    font-size: 0.9em;
    text-decoration: none;
    transition: color 0.25s ease-in-out;
  }

  ul li a:hover {
    color: #333;
  }
}

.footerCopyrightText {
  width: 100%;
  background-color: #f1f1f1;

  text-align: center;
  color: #2f2f2f;

  p,
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    margin: auto;
    max-width: 1360px;
    padding: 20px 100px;
    gap: 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }
}

@media (max-width: 1800px) {
  .reassurance {
    padding: 20px 100px;

    div {
      padding: 0;
    }

    div address {
      font-size: 1.1em;
    }

    iframe {
      height: 25vh;
      width: 35vw;
    }
  }
}

@media (max-width: 1280px) {
  .reassurance {
    padding: 20px 50px;

    iframe {
      min-width: 475px;
    }
  }

  .footer {
    padding: 30px 50px 20px;
  }
}

@media (max-width: 1000px) {
  .reassurance {
    flex-direction: column;
    padding-bottom: 30px;
    gap: 25px;

    div {
      align-items: center;
    }

    div h3 {
      display: flex;
      gap: 15px;
      border-bottom-left-radius: 10px;
    }

    div h3 br {
      display: none;
    }

    div address {
      text-align: center;
      font-weight: 500;
      padding: 0 10px;
    }
  }

  .footer {
    padding: 40px;
  }

  .footerContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .footerCopyrightText {
    padding: 20px 40px 30px;
  }
}

@media (max-width: 768px) {
  .reassurance {
    background-image: linear-gradient(
        90deg,
        #1a6408c9,
        #1a6408c4 100%,
        #1a6408a6 0
      ),
      url(/htdocs/images/assets/cours-gite.jpg);
    background-attachment: scroll;

    div h3 {
      font-size: 2em;
      font-weight: 600;
      padding: 0 10px 0px;
      border-bottom: 10px solid #75ff2f;
      letter-spacing: 1px;
    }

    div address {
      font-weight: 500;
      font-size: 0.9em;
    }
  }

  .footer {
    padding: 40px 40px 20px;
  }

  .footerContainer {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    div p,
    div li,
    div a,
    div h2 {
      text-align: center;
    }

    div h2 {
      font-size: 1.25em;
    }

    div ul li a {
      font-size: 0.875em;
      font-weight: 300;
      justify-content: center;
    }

    .footerSocialLinks {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      gap: 50px;
    }

    .footerSecAboutUs {
      h2 {
        font-size: 1.25em;
      }

      p {
        font-size: 0.875em;
        font-weight: 300;
      }
    }

    .footerContact {
      h2 {
        font-size: 1.25em;
      }
    }
  }

  .footerCopyrightText {
    padding: 5px 0 0;

    p,
    span {
      font-size: 0.875em;
      font-weight: 300;
    }

    p {
      padding: 20px;
    }
  }
}

@media (max-width: 620px) {
  .footer {
    padding: 20px 0px 0;
  }

  .reassurance {
    padding: 30px 0;

    div h3 {
      font-size: 1.75em;
      text-align: center;
      justify-content: center;
    }

    iframe {
      width: 90vw;
      min-width: 0;
      max-width: none;
      max-height: none;
      height: 225px;
      border-radius: 15px;
    }
  }
}

@media (max-width: 375px) {
  .reassurance {
    div h3 {
      font-size: 1.9em;
    }
  }
}
