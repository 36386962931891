.nav {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  padding: 0 1vw;
  gap: 3vh;
}
.nav h1 {
  padding: 10px 0;
}
.nav h1 a {
  text-decoration: none;
  font-size: 2.4em;
  color: rgb(255, 255, 255);
}
.nav ul {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 1vw;
  width: 100%;
  max-width: 1920px;
}
.nav ul:first-child svg,
.nav ul a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #f5f5f5;
  font-size: 0.95em;
  letter-spacing: 0.07em;
  text-decoration: none;
  min-height: 43px;
  padding: 7.5px 10px 7.5px 15px;
  border-radius: 10px;
  background-color: hsla(0, 0%, 37.16%, 0.45);
  backdrop-filter: blur(20px) brightness(0.75);
  transition: backdrop-filter 0.25s ease-in-out, background-color 0.25s ease-in-out;
  cursor: pointer;
  overflow: hidden;
}
.nav ul:first-child svg {
  padding: 7.5px 10px;
}
.nav ul:first-child svg:active,
.nav ul:first-child svg:hover,
.nav ul a:active,
.nav ul a:hover {
  background-color: hsla(0, 0%, 57.15%, 0.69);
}
.nav ul a[href="#/login?param=register"]::after,
.nav ul a[href="#/register"]::after {
  display: inline-block;
  width: 1.75em;
  height: 1.75em;
  vertical-align: -0.125em;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M15 14c-2.67 0-8 1.33-8 4v2h16v-2c0-2.67-5.33-4-8-4m-9-4V7H4v3H1v2h3v3h2v-3h3v-2m6 2a4 4 0 0 0 4-4a4 4 0 0 0-4-4a4 4 0 0 0-4 4a4 4 0 0 0 4 4'/%3E%3C/svg%3E");
}
.nav ul li {
  list-style: none;
}
.nav ul a[href="#/profile"]::after,
.nav ul a[href="#/login"]::after {
  display: inline-block;
  width: 1.75em;
  height: 1.75em;
  vertical-align: -0.125em;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M12 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4'/%3E%3C/svg%3E");
}
.nav ul a[target=_blank]::after {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: -0.125em;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M12 1L3 5v6c0 5.55 3.84 10.74 9 12c5.16-1.26 9-6.45 9-12V5Zm0 3.9a3 3 0 1 1-3 3a3 3 0 0 1 3-3m0 7.9c2 0 6 1.09 6 3.08a7.2 7.2 0 0 1-12 0c0-1.99 4-3.08 6-3.08'/%3E%3C/svg%3E");
}
.nav > aside {
  background-color: hsla(0, 0%, 57%, 0.45);
  border-radius: 20px;
  overflow: hidden;
}

@media (max-width: 1800px) {
  .nav {
    gap: 6vh;
  }
}
@media (max-width: 1000px) {
  .nav {
    justify-content: flex-end;
    align-items: flex-end;
    height: auto;
    padding: 0;
  }
  .nav > ul {
    padding-right: 20px;
  }
  .nav > aside {
    border-radius: 0;
  }
  .nav aside {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .nav > ul {
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 0 10px 40px 0;
    gap: 2vw;
  }
  .nav ul a span {
    display: none;
  }
  .nav ul a,
  .nav ul:first-child svg {
    padding: 4.5px 9px;
    width: 43px;
  }
}
@media (max-width: 620px) {
  .nav {
    gap: 0;
  }
  .nav > aside {
    background-color: transparent;
  }
  .nav ul a,
  .nav ul:first-child svg {
    justify-content: center;
    width: 37.5px;
    height: 37.5px;
    min-height: 37.5px;
    padding: 0;
    border-radius: 20px;
    background-color: #494949;
    backdrop-filter: blur(100px) brightness(0.5);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  }
  .nav ul:first-child svg {
    padding: 4.5px 9px;
  }
  .nav ul a::after {
    width: 20px !important;
    height: 20px !important;
  }
}