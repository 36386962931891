.form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}
.form textarea,
.form select,
.form input {
  font-size: 1.25em;
  letter-spacing: 0.05em;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  border: none;
  background-color: white;
  border-radius: 20px;
  padding: 10px 20px;
  min-height: 35px;
  min-width: 300px;
  cursor: pointer;
  width: 100%;
  flex: 0.7;
}
.form input::placeholder {
  font-weight: 400;
  font-size: 1.15em;
  color: rgba(153, 153, 153, 0.3882352941);
}
.form textarea {
  height: 150px;
  resize: none;
}
.form fieldset {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
  border: none;
}
.form button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  min-height: 45px;
  max-width: 200px;
  background-color: green;
  font-size: 18px;
  color: #fff;
  border-radius: 30px;
  padding: 7px 25px;
}
.form ul div {
  border-left: none;
}
.form span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  background-color: #c10000;
  border-radius: 5px;
  padding: 8px 12.5px;
  color: #fff;
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 20px;
}
.form span svg {
  min-width: 30px;
  min-height: 30px;
}

@media (max-width: 515px) {
  .form ul.error {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7490196078);
    width: 100%;
    height: 100%;
    z-index: 10;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .form ul.error div {
    width: 90vw;
  }
  .form span {
    max-width: 90%;
  }
}