.periodsBooking {
  max-height: 0;
  opacity: 0;
  display: flex;
  justify-content: flex-start;
  height: auto;
  width: 100%;
  margin: auto;
  max-width: 1500px;
}
.periodsBooking > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border-radius: 25px;
  margin-left: 70px;
  padding: 20px 30px 40px;
  width: 100%;
}
.periodsBooking h4 {
  margin-bottom: 20px;
  font-size: 1.5em;
}
.periodsBooking > div div[id=bookStart] + h4 {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #999;
  width: 100%;
}
.periodsBooking aside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  min-height: 0;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: min-height 0.5s ease-in-out, height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.periodsBooking aside.activeBtn {
  min-height: 75px;
  height: auto;
  opacity: 1;
}
.periodsBooking > div > aside:first-child {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  padding: 0 20px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #999;
}
.periodsBooking > div > div[id=change_date] {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 35px;
  padding: 12.5px 15px;
  border-radius: 20px;
  background-color: rgba(54, 210, 54, 0.141);
  color: #0da72c;
  letter-spacing: 0.03em;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 0.95em;
}
.periodsBooking > div > div[id=change_date] label {
  line-height: 24px;
}
.periodsBooking > div > div[id=change_date] button {
  background: #017143;
  background: linear-gradient(-180deg, #00a314, #0da72c 30%, #2fab18);
  border: none;
  border-radius: 40px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1em;
  font-weight: 500;
  height: 50px;
  letter-spacing: 1px;
  margin: 20px 0;
  max-width: fit-content;
  padding: 12px 17.5px;
  text-decoration: none;
  transition: background-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}
.periodsBooking aside button {
  display: inline-block;
  background: rgb(1, 113, 67);
  background: linear-gradient(-180deg, #00a314, #0da72c 30%, #2fab18);
  color: #fff;
  font-weight: 500;
  border: none;
  border-radius: 40px;
  letter-spacing: 1px;
  text-decoration: none;
  max-width: fit-content;
  height: 50px;
  margin: 20px 0;
  padding: 12px 17.5px;
  cursor: pointer;
  font-size: 1.1em;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
  transition: background-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}
.periodsBooking aside button:disabled {
  background: #666;
  cursor: default;
  transition: none;
}
.periodsBooking > div > div[id=change_date] button:hover,
.periodsBooking aside button:not(button:disabled):hover {
  background-color: #0da72c;
}
.periodsBooking > div > div[id=change_date] button:active,
.periodsBooking aside button:active {
  box-shadow: inset rgba(0, 0, 0, 0.2) 0px 2px 10px;
}
.periodsBooking aside label,
.periodsBooking aside p {
  color: #333;
  font-family: Poppins, sans-serif;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 21px;
}
.periodsBooking aside p {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 1em;
  min-width: 500px;
}
.periodsBooking aside p svg {
  width: 2em;
  height: 2em;
}
.periodsBooking aside label {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 20px;
  cursor: pointer;
  text-decoration: underline;
}
.periodsBooking aside > label {
  border-bottom: 1px outset rgba(91, 91, 91, 0.1019607843);
  justify-content: space-between;
  width: 100%;
}
.periodsBooking aside label[for=clean],
.periodsBooking aside label[for=caution],
.periodsBooking aside label[for=qtyTraveller] {
  text-decoration: none;
}
.periodsBooking aside label div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.periodsBooking aside label div span {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsla(0, 0%, 83%, 0.86);
  border-radius: 5px;
  width: 37.5px;
  height: 37.5px;
  line-height: normal;
  font-weight: 400;
  text-decoration: underline;
  text-decoration-color: #333;
  transition: background-color 0.25s ease-in-out, border-radius 0.25s ease-in, color 0.25s ease-out, text-decoration-color 0.25s ease-in, font-weight 0.25s ease-in-out;
}
.periodsBooking aside label div span:hover {
  text-decoration-color: transparent;
}
.periodsBooking aside label div span.choice {
  color: #fff;
  background-color: #00a314;
  border-radius: 10px;
  font-weight: 600;
  text-decoration-color: transparent;
}
.periodsBooking aside label input[type=checkbox] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  background-color: hsla(0, 0%, 82.85%, 0.86);
  border-radius: 5px;
  min-width: 19.5px;
  height: 19.5px;
  transition: background-color 0.25s ease-in-out;
}
.periodsBooking aside label input[type=checkbox]:checked {
  z-index: 1;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23666' d='M12 7a5 5 0 1 1-4.995 5.217L7 12l.005-.217A5 5 0 0 1 12 7'/%3E%3C/svg%3E");
  min-width: 19.5px;
  height: 19.5px;
  background-color: hsla(0, 0%, 82.85%, 0.86);
}
.periodsBooking aside label[for=clean] {
  flex-direction: row;
}
.periodsBooking .assurance {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  width: 430px;
  height: auto;
  right: 5%;
  top: -175px;
  padding: 30px 20px;
  border-radius: 20px;
  background-color: #c5c5c5;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  transition: opacity 0.25s ease-in-out;
}
.periodsBooking .assurance.active {
  visibility: visible;
  opacity: 1;
}

@keyframes appearCalendar {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 100000vh;
    opacity: 1;
  }
}
.periodsBooking.activeBooking {
  animation: appearCalendar 2s linear 4s forwards;
}

@media (min-width: 1921px) {
  .periodsBooking > div {
    margin-left: 20px;
  }
}
@media (max-width: 1500px) {
  .periodsBooking {
    padding-left: 20px;
    padding-right: 20px;
  }
  .periodsBooking > div {
    margin-left: 0;
  }
}
@media (max-width: 1280px) {
  .periodsBooking aside {
    align-items: flex-start;
  }
  .periodsBooking aside button {
    margin: 10px auto;
  }
  .periodsBooking > div > aside:first-child {
    padding-bottom: 20px;
  }
}
@media (max-width: 1000px) {
  .periodsBooking > div {
    margin-top: 20px;
  }
}
@media (max-width: 870px) {
  .periodsBooking aside p {
    align-items: center;
  }
  .periodsBooking aside label {
    flex-direction: column;
    align-items: flex-start;
    line-height: 27px;
  }
  .periodsBooking aside label [for=qtyTraveller] {
    margin-top: 0;
  }
  .periodsBooking aside label input[type=checkbox]:checked,
  .periodsBooking aside label input[type=checkbox] {
    height: 23px;
    min-width: 23px;
  }
}
@media (max-width: 768px) {
  .periodsBooking > div {
    box-shadow: none;
    padding: 20px 10px;
  }
  .periodsBooking aside label {
    padding: 20px 0;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .periodsBooking aside label[for=clean] > span {
    width: 100%;
  }
  .periodsBooking aside label[for=assurance] span {
    margin-bottom: 20px;
  }
}
@media (max-width: 620px) {
  .periodsBooking aside p {
    flex-wrap: wrap;
    min-width: 0;
    font-size: 0.9em;
  }
  .periodsBooking aside p b {
    display: block;
    width: 100%;
  }
  .periodsBooking aside p svg {
    width: 1.5em;
    height: 1.5em;
  }
  .periodsBooking aside label {
    margin: 20px 0;
    font-size: 0.9em;
  }
  .periodsBooking aside label:nth-child(2) {
    flex-direction: row;
  }
  .periodsBooking aside label div span {
    width: 34.5px;
    height: 34.5px;
  }
  .periodsBooking > div > div[id=change_date] {
    margin-top: 20px;
  }
  .periodsBooking > div > div[id=change_date] button {
    height: auto;
  }
  .periodsBooking aside label input[type=checkbox]:checked,
  .periodsBooking aside label input[type=checkbox] {
    margin-top: 25px;
  }
  .periodsBooking aside button {
    font-size: 1em;
  }
  .periodsBooking h4 {
    font-size: 1.25em;
    margin-top: 20px;
    padding-left: 20px;
  }
  .periodsBooking > div > aside:first-child {
    border-bottom: 1px solid rgba(153, 153, 153, 0.2705882353);
    padding: 0 10px 20px;
    margin-bottom: 0;
  }
  .periodsBooking > div div[id=bookStart] + h4 {
    margin-top: 0;
    padding-top: 20px;
  }
}
@media (max-width: 515px) {
  .periodsBooking aside label {
    justify-content: flex-end;
    align-items: flex-end;
    margin: 5px 0;
  }
  .periodsBooking aside label input[type=checkbox],
  .periodsBooking aside label input[type=checkbox]:checked {
    margin-top: -20px;
  }
  .periodsBooking aside label[for=clean] {
    align-items: center;
    padding-top: 0;
  }
  .periodsBooking aside label[for=qtyTraveller] {
    align-items: center;
  }
  .periodsBooking .assurance {
    z-index: 1;
    width: auto;
    right: 0%;
    top: -205px;
  }
}