.calendar {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 70px;
  width: 100%;
  max-height: 600px;
  overflow-y: auto;
  padding-bottom: 10px;

  h5 {
    text-transform: capitalize;
    font-size: 1.05em;
    text-align: center;
  }

  article {
    width: 260px;
    height: 235px;
  }
}

.rowCalendar {
  display: flex;
  flex-direction: column;
  width: 100%;

  .days,
  .dates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .days {
    border-bottom: 1px solid #999;
    margin-bottom: 5px;
  }

  .dates {
    justify-content: flex-start;
  }

  .dates:nth-child(2) {
    justify-content: flex-end;
  }

  .days span,
  .dates span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    text-align: start;
  }

  .days span {
    font-size: 0.875em;
  }

  .dates span {
    font-size: 0.9em;
    color: #b3b3b3;
    user-select: none;
    background-color: transparent;
    border-radius: 5px;
    transition: background-color 0.15s ease-in-out, border-radius 0.25s ease-in-out;
    margin: 1px;
  }

  .dates span:not(.available) {
    text-decoration: line-through;
  }

  .dates span.available {
    cursor: pointer;
    color: #0da72c;
    user-select: initial;
    font-size: 0.95em;
    background-color: #36d23624;
  }

  .dates span.available b {
    font-weight: 600;
  }

  .dates span.available.dateActive,
  .dates span.available:hover {
    background-color: #0bd333d5;
    border-radius: 10px;
  }

  .dates span.available.dateActive b,
  .dates span.available:hover b {
    text-shadow: 0 0 20px rgb(0 0 0);
    color: #fff;
  }
}

.calendar::-webkit-scrollbar {
  display: block;
  width: 5px;
}

.calendar::-webkit-scrollbar-thumb {
  background-color: #8e8e8e;
}

.calendar[id="bookEnd"] {
  margin-bottom: 40px;

  .dates span.available {
    user-select: initial;
    cursor: pointer;
    color: #0d3ea7;
    background-color: #17589333;
  }

  .dates span.available.dateActive,
  .dates span.available:hover {
    background-color: #0b3ad3d5;
    border-radius: 10px;
  }

  .dates span.available.dateActive b,
  .dates span.available:hover b {
    text-shadow: 0 0 20px rgb(0 0 0);
    color: #fff;
  }
}

.noCalendar {
  margin-top: 50px;
  font-weight: 600;
}

.noCalendar::before {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: -0.125em;
  margin-right: 10px;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 11.5v5m0-8.99l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10'/%3E%3C/svg%3E");
}

@media (max-width: 1000px) {
  .rowCalendar {
    .dates span:not(.available) {
      font-weight: 500;
    }
  }
}

@media (max-width: 768px) {
  .calendar {
    justify-content: space-around;
    gap: 0;

    article {
      height: 300px;
      width: 250px;
    }
  }
}

@media (max-width: 620px) {
  .calendar {
    align-items: center;
    flex-wrap: nowrap;
    gap: 45px;

    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 90vw;
    overflow-x: scroll;

    h5 {
      font-size: 0.9em;
    }
  }

  .rowCalendar {
    .dates span.available b {
      font-size: 0.875em;
    }

    .days span,
    .dates span {
      width: 32.5px;
      height: 32.5px;
    }

    .days span {
      font-size: 0.8em;
    }
  }
}
