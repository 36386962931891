.userDatas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
  width: 100%;
  max-width: 800px;
}
.userDatas h3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 27px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
.userDatas > section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 60px;
  margin: auto;
  width: 100%;
  padding: 25px;
  border-radius: 20px;
  border-top: 1px solid rgba(197, 197, 197, 0.4509803922);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 4px;
}
.userDatas section div:first-child {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
}
.userDatas section div:nth-child(2) {
  padding: 20px;
}
.userDatas form,
.userDatas address,
.userDatas fieldset {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: none;
  gap: 20px;
  border-radius: 5px;
  width: 50%;
}
.userDatas form {
  width: 100%;
}
.userDatas address {
  position: relative;
}
.userDatas p,
.userDatas select,
.userDatas input,
.userDatas button {
  font-size: 1.25em;
  letter-spacing: 0.05em;
  border: none;
  border-top: 1px solid rgba(197, 197, 197, 0.4509803922);
  box-shadow: rgba(191, 191, 191, 0.5) 0px 5px 10px;
  border-radius: 20px;
  padding: 10px 20px;
  min-height: 35px;
  min-width: 300px;
  cursor: pointer;
  width: 100%;
  transition: box-shadow 0.5s ease-in-out;
}
.userDatas input,
.userDatas p {
  max-width: 300px;
  font-size: 1em !important;
  padding: 13.5px 20px 0px;
  min-height: 43px;
}
.userDatas input {
  padding: 4.5px 20px 0px;
}
.userDatas input:hover,
.userDatas input:active {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
}
.userDatas button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  min-height: 0;
  min-width: 200px;
  max-width: 250px;
}
.userDatas .datas button:disabled,
.userDatas button:disabled {
  background: #999;
  cursor: default;
}
.userDatas button:disabled:hover {
  filter: brightness(1);
}
.userDatas button:disabled:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
}
.userDatas .datas {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.userDatas .datas ul {
  width: 100%;
}
.userDatas .datas div:not(div[class=error-messages]):first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}
.userDatas .datas button {
  font-size: 1em;
  color: #fff;
  height: 43px;
  background: linear-gradient(-180deg, #00a314, #0da72c 30%, #2fab18);
}
.userDatas .datas button[role=none] {
  background: linear-gradient(155deg, #e30000, #d40000 70%, #d60202);
}

@media (max-width: 1280px) {
  .userDatas > section {
    justify-content: center;
  }
  .userDatas form,
  .userDatas address,
  .userDatas fieldset {
    align-items: center;
  }
}
@media (max-width: 870px) {
  .userDatas > section {
    border-top: none;
    box-shadow: none;
    padding-bottom: 0;
  }
}
@media (max-width: 768px) {
  .userDatas .datas {
    flex-direction: column;
  }
  .userDatas .datas div,
  .userDatas .datas div:not(div[class=error-messages]):first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .userDatas .datas button {
    margin-top: 25px;
  }
  .userDatas .datas button[role=none] {
    margin-bottom: 50px;
  }
}
@media (max-width: 620px) {
  .userDatas h3 {
    font-size: 1.25em;
  }
  .userDatas input,
  .userDatas p,
  .userDatas input::placeholder,
  .userDatas .datas button {
    font-size: 0.9em !important;
  }
  .userDatas .datas button {
    margin-top: 20px;
  }
  .userDatas .datas button[role=none] {
    margin-bottom: 30px;
  }
  .userDatas .datas button:last-child {
    margin-top: 0;
  }
  .userDatas input::placeholder {
    opacity: 1;
    color: #333;
  }
  .userDatas input {
    padding: 0 20px 0;
  }
}
@media (max-width: 515px) {
  .userDatas h3 {
    font-size: 1.15em;
  }
}