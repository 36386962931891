.sliderContainer {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8901960784);
  z-index: 1000;
}
.sliderContainer > svg {
  position: absolute;
  left: 30px;
  top: 30px;
  color: #111;
  padding: 5px;
  width: 2em;
  height: 2em;
  cursor: pointer;
  border: transparent 1px solid;
  transition: border 0.25s ease-in-out;
}
.sliderContainer svg:hover {
  border-color: #999;
}

.slider {
  position: absolute;
  inset: 80px 200px 80px 80px;
  background-color: #ccc;
  border-radius: 20px;
  z-index: 1001;
  top: 25%;
  left: 25%;
  transform: translate(-20%, -15%);
  max-width: 1150px;
  max-height: 768px;
}

.sliderSlides {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 150%;
  transform: translateY(-50%);
  width: 240px;
  height: 320px;
  background: var(--img);
  background-size: cover;
  background-position: center;
  transition: 0.5s;
  border-radius: 20px;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.5);
  z-index: 1002;
}

.sliderSlides:nth-child(1),
.sliderSlides:nth-child(2) {
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(0);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.5);
  background-size: cover;
}

.sliderSlides:nth-child(3) {
  opacity: 1;
  left: calc(50% + 32vw);
  top: 55%;
}

.sliderSlides:nth-child(4) {
  opacity: 1;
  left: calc(50% + 36vw);
}

.sliderSlides:nth-child(5) {
  opacity: 1;
  left: calc(50% + 40vw);
  top: 45%;
}

.sliderSlides:nth-child(6) {
  opacity: 1;
  left: calc(50% + 44vw);
  opacity: 0;
  top: 35%;
}

.buttons {
  position: absolute;
  bottom: 15px;
  display: flex;
  gap: 20px;
}
.buttons span {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: #111;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.buttons span::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border-top: 4px solid #fff;
  border-left: 4px solid #fff;
  transform: rotate(315deg) translate(2px, 2px);
}
.buttons span:nth-child(2)::before {
  transform: rotate(135deg) translate(2px, 2px);
}
.buttons span:active {
  opacity: 0.5;
}

.sliderContent {
  position: relative;
  padding: 40px;
  max-width: 600px;
  transition: 0.25s;
  transition-delay: 0s;
  transform: translateY(40px);
  z-index: 1003;
  opacity: 0;
}
.sliderContent h2 {
  font-size: 4em;
  color: #fff;
}
.sliderContent p {
  color: #fff;
}

.sliderSlides:nth-child(1) .sliderContent,
.sliderSlides:nth-child(2) .sliderContent {
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 0.5s;
}

/* ::::::::::::::::::::::::::::::::::::::::::::::::: */
/* PRIVACY */
.privacy {
  padding: 20px 40px;
  overflow-y: auto;
  overflow-x: hidden;
  inset: 80px auto;
  transform: none;
  left: auto;
  top: auto;
  max-width: 1360px;
  border-radius: 0;
  width: 85vw;
}
.privacy > ul {
  padding: 20px;
}
.privacy > ul li {
  list-style: "- ";
  margin-bottom: 7.5px;
}
.privacy ol {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.privacy ol li {
  padding-left: 20px;
}
.privacy ol li ul {
  margin-top: 25px;
}
.privacy ol li ul li {
  list-style: disc;
}

/* ::::::::::::::::::::::::::::::::::::::::::::::::: */
/* SUCCESS/ERROR */
.sliderContainer.not_privacy .slider {
  background-color: transparent;
  max-height: none;
}
.sliderContainer.not_privacy .error,
.sliderContainer.not_privacy .success,
.sliderContainer.not_privacy .success_reset {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  height: auto;
  max-height: 765px;
  max-width: 850px;
  margin: auto;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.16);
  background: linear-gradient(155deg, rgb(33, 113, 1) 0%, rgb(13, 167, 75) 55%, rgb(47, 215, 255) 100%);
  padding: 40px 35px;
  position: relative;
  overflow: hidden;
}
.sliderContainer.not_privacy .error {
  height: auto;
  background: linear-gradient(155deg, #c17500, #a78d0d 55%, #c17500);
}
.sliderContainer.not_privacy .success_reset {
  max-width: 750px;
}
.sliderContainer.not_privacy .success svg {
  position: absolute;
  left: initial;
  top: initial;
  right: -600px;
  bottom: -600px;
  padding: 0;
  width: 75em;
  height: 75em;
  cursor: default;
  border: none;
}
.sliderContainer.not_privacy .error svg {
  display: none;
}
.sliderContainer.not_privacy .error h2,
.sliderContainer.not_privacy .success h2,
.sliderContainer.not_privacy .success_reset h2 {
  color: #fff;
  font-size: 2em;
  font-family: "Lato", sans-serif;
}
.sliderContainer.not_privacy .error p,
.sliderContainer.not_privacy .success p,
.sliderContainer.not_privacy .success_reset p {
  color: #fff;
  font-size: 1.1em;
  line-height: 27px;
  max-width: 800px;
  z-index: 1;
}
.sliderContainer.not_privacy .error p span,
.sliderContainer.not_privacy .success p span {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 50px;
  margin-top: 40px;
  width: 100%;
}
.sliderContainer.not_privacy .error p em,
.sliderContainer.not_privacy .success p em {
  font-size: 1.5em;
  font-style: normal;
}
.sliderContainer.not_privacy .error a,
.sliderContainer.not_privacy .success a,
.sliderContainer.not_privacy .success_reset a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  gap: 10px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  text-decoration: none;
  color: #333;
  font-size: 0.9em;
  font-weight: 500;
  line-height: normal;
  min-height: 40px;
  letter-spacing: 1px;
  max-width: fit-content;
  padding: 0px 17.5px 0;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.5s ease-in-out, box-shadow 0.75s ease-in-out;
}
.sliderContainer.not_privacy .error a:active,
.sliderContainer.not_privacy .success a:active,
.sliderContainer.not_privacy .success_reset a:active {
  box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.2);
}
.sliderContainer.not_privacy .error a svg,
.sliderContainer.not_privacy .success a svg {
  display: block;
  position: static;
  padding: 0;
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
}

/* ::::::::::::::::::::::::::::::::::::::::::::::::: */
/* RESET PASSWORD */
.sliderContainer.not_privacy .slider {
  background-color: transparent;
  max-height: none;
}
.sliderContainer.not_privacy .reset {
  width: 100%;
  height: 75vh;
  max-width: 515px;
  max-height: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #fff;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.3);
  transition: 0.5s;
}
.sliderContainer.not_privacy .reset form {
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  width: 100%;
  overflow-y: auto;
}
.sliderContainer.not_privacy .reset h3 {
  font-size: 1.5em;
  font-weight: 600;
  padding: 0 50px;
  margin-bottom: 40px;
  color: #333;
}
.sliderContainer.not_privacy .reset input {
  width: 100%;
  min-width: 300px;
  min-height: 35px;
  max-width: 350px;
  margin-bottom: 15px;
  padding: 10px;
  outline: none;
  flex: none;
  font-size: 0.9em;
  letter-spacing: 0.1em;
  background-color: #e9e9e9;
  border-radius: 0;
  box-shadow: none;
  border: none;
}
.sliderContainer.not_privacy .reset input::placeholder {
  color: #222;
  font-weight: 400;
  text-transform: lowercase;
  font-size: 0.9em;
}
.sliderContainer.not_privacy .reset input::placeholder::first-letter {
  text-transform: capitalize;
}
.sliderContainer.not_privacy .reset button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background: linear-gradient(155deg, #0f9310, #0ea32c 70%, #43a70f);
  border: none;
  color: #fff;
  padding: 5px 10px 5px 15px;
  min-height: 40px;
  max-width: 180px;
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 1em;
  font-weight: 500;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: none;
}
.sliderContainer.not_privacy .reset button:hover {
  filter: brightness(1);
}
.sliderContainer.not_privacy .reset button:disabled {
  background: #999;
  color: #f9f9f9;
  cursor: default;
}
.sliderContainer.not_privacy .reset button:not(:disabled):active,
.sliderContainer.not_privacy .reset button:not(:disabled):active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
}

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* |-|-|-|-|-|-|-|-|-|-|-| RESPONSIVE |-|-|-|-|-|-|-|-|-|-|-| */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
@media (min-height: 1080px) {
  .slider.privacy {
    top: 7.5%;
  }
}
@media (max-width: 1280px) {
  .sliderSlides {
    left: 155%;
  }
  .sliderContainer.not_privacy .error,
  .sliderContainer.not_privacy .success,
  .sliderContainer.not_privacy .success_reset {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .sliderContainer.not_privacy .success svg {
    display: none;
  }
}
@media (max-width: 1000px) {
  .privacy {
    padding: 15px 20px;
  }
  .sliderSlides {
    left: 165%;
  }
}
@media (max-width: 900px) {
  .slider:not(.privacy) {
    position: absolute;
    inset: 40px 40px 200px 40px;
    background-color: transparent;
    transform: translate(0%, -15%);
  }
  .buttons {
    top: 85vh;
  }
  .sliderSlides {
    width: 100px;
    height: 100px;
    top: initial;
    bottom: -185px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  }
  .sliderSlides:nth-child(1),
  .sliderSlides:nth-child(2) {
    top: 20vh;
  }
  .sliderSlides:nth-child(3) {
    left: 0;
    top: auto;
    opacity: 0;
  }
  .sliderSlides:nth-child(4) {
    left: 120px;
    top: auto;
    opacity: 0;
  }
  .sliderSlides:nth-child(5) {
    left: 240px;
    top: auto;
    opacity: 0;
  }
  .sliderSlides:nth-child(6) {
    left: 360px;
    top: auto;
    opacity: 0;
  }
}
@media (max-width: 768px) {
  .slider:not(.privacy) {
    transform: translate(0%, -50%);
    inset: 30vh 10px 200px 10px;
    max-width: none;
    max-height: 30vh;
  }
  .sliderSlides {
    width: 100%;
    height: auto;
    min-height: auto;
  }
  .sliderContainer.not_privacy .error,
  .sliderContainer.not_privacy .success,
  .sliderContainer.not_privacy .success_reset {
    padding: 40px;
  }
  .sliderContainer.not_privacy .success svg {
    display: none;
  }
  .sliderContainer.not_privacy .success h2,
  .sliderContainer.not_privacy .error h2,
  .sliderContainer.not_privacy .success_reset h2 {
    font-size: 2em;
  }
  .sliderContainer.not_privacy .error p,
  .sliderContainer.not_privacy .success p,
  .sliderContainer.not_privacy .success_reset p {
    font-weight: 400;
  }
  .sliderContainer.not_privacy .error a,
  .sliderContainer.not_privacy .success a,
  .sliderContainer.not_privacy .success_reset a,
  .sliderContainer.not_privacy .error p,
  .sliderContainer.not_privacy .success p,
  .sliderContainer.not_privacy .success_reset p {
    font-size: 1em;
  }
  .sliderContainer.not_privacy .error a,
  .sliderContainer.not_privacy .success a,
  .sliderContainer.not_privacy .success_reset a {
    font-size: 0.9em;
    height: auto;
    padding: 10px 17.5px;
    align-items: center !important;
  }
}
@media (max-width: 620px) {
  .sliderContainer.not_privacy .reset h3 {
    padding: 0 25px;
  }
  .sliderContainer.not_privacy .reset form {
    padding: 0 30px;
  }
  .sliderContainer.not_privacy .error a,
  .sliderContainer.not_privacy .success a,
  .sliderContainer.not_privacy .success_reset a,
  .sliderContainer.not_privacy .error p,
  .sliderContainer.not_privacy .success p,
  .sliderContainer.not_privacy .success_reset p {
    line-height: 21px;
    font-size: 0.9em;
  }
}
@media (max-width: 515px) {
  .slider.privacy {
    width: 100vw;
    top: 9.5%;
  }
  .slider.privacy ul li {
    line-height: 22px;
    font-weight: 400;
  }
  .sliderContainer.not_privacy .slider {
    background-color: transparent;
  }
  .sliderContainer.not_privacy .error,
  .sliderContainer.not_privacy .success,
  .sliderContainer.not_privacy .success_reset {
    gap: 15px;
    padding: 20px;
  }
  .sliderContainer.not_privacy .error p span,
  .sliderContainer.not_privacy .success p span {
    gap: 20px;
    margin-top: 20px;
  }
  .sliderContainer.not_privacy .success h2,
  .sliderContainer.not_privacy .error h2,
  .sliderContainer.not_privacy .success_reset h2 {
    font-size: 1.5em;
  }
  .sliderContainer.not_privacy .error a,
  .sliderContainer.not_privacy .success a,
  .sliderContainer.not_privacy .success_reset a {
    padding: 9px 14.5px;
  }
  .sliderContainer.not_privacy .error a svg,
  .sliderContainer.not_privacy .success a svg {
    display: none;
  }
  .sliderContainer.not_privacy .reset {
    justify-content: center;
    height: 500px;
  }
  .sliderContainer.not_privacy .reset h3,
  .sliderContainer.not_privacy .reset form {
    padding: 0 20px;
  }
}
@media (max-width: 375px) {
  .sliderContainer.not_privacy .reset h3,
  .sliderContainer.not_privacy .reset form {
    padding: 0 15px;
  }
  .sliderContainer.not_privacy .reset input {
    min-width: 0;
  }
}