.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  min-height: 500px;
  width: 100%;
  padding: 60px 15vw 100px;
  background-color: rgba(241, 241, 241, 0.9450980392);
}
.notFound section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1360px;
  width: 100%;
}
.notFound div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.notFound h2 {
  font-size: 2em;
}
.notFound h3 {
  font-size: 1.2em;
}

@media (max-width: 620px) {
  .notFound {
    flex-direction: column;
  }
  .notFound h2 {
    font-size: 1.5em;
    font-weight: 600;
  }
  .notFound h3 {
    font-weight: 400;
  }
  .notFound div {
    align-items: center;
  }
}