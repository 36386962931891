.profilBridge {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 50px;
  padding: 20px 2.5%;
  width: 100%;
  min-height: 50vh;
  background-color: rgba(241, 241, 241, 0.9450980392);
}
.profilBridge > section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  padding: 25px;
  border-radius: 20px;
  border-top: 1px solid rgba(197, 197, 197, 0.4509803922);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 4px;
  background-color: #fff;
  transition: box-shadow 0.5s ease-in-out;
}
.profilBridge p {
  font-size: 1em;
}
.profilBridge h2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1.15em;
  margin-bottom: 20px;
  width: 100%;
}

@media (max-width: 1500px) {
  .profilBridge {
    padding: 20px 1%;
  }
}
@media (max-width: 870px) {
  .profilBridge {
    background-color: #fff;
  }
  .profilBridge > section {
    box-shadow: none;
    border-top: none;
    padding: 25px 10px;
  }
  .profilBridge p:not(.statusBooking) {
    font-size: 1em;
    padding: 12.5px 20px 0px;
  }
  .profilBridge h2 {
    padding-left: 10px;
    font-size: 1.5em;
    margin-bottom: 0;
  }
  .profilBridge h2 svg {
    width: 65px;
    height: 65px;
  }
}
@media (max-width: 620px) {
  .profilBridge {
    gap: 0;
  }
}
@media (max-width: 515px) {
  .profilBridge h2 {
    font-size: 1.25em;
    margin-bottom: 0;
  }
  .profilBridge h2 svg {
    width: 50px;
    height: 50px;
  }
  .profilBridge p {
    text-align: start;
  }
}